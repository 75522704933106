import React, { useState } from "react";
import {
  Button,
  DateInput,
  FileUploader,
  Input,
  Select,
} from "../../../components/ui";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import { useEmployeeContext } from "../../../providers/EditEmployeeProvider";
import useUpload from "../../../hooks/useUpload";
import Spinner from "../../../components/loader/Spinner";
import DocumentsDrawer from "./DocumentsDrawer";
import Checkbox from "../../../components/ui/Checkbox";
import { useParams } from "react-router-dom";
import { dateObjToTimestamp, dateTimestampToObj } from "../../../utility/utils";
import { isObject } from "lodash";

const WorkDetailsForm = ({
  onNextStep,
  onPreviousStep,
  employmentList,
  shiftList,
  departmentListing,
  isCreation,
}) => {
  const { id } = useParams();
  const [fileType, setFileType] = useState("");
  const [
    control,
    handleSubmit,
    setValue,
    getValues,
    educationDoc,
    setEducationDoc,
    workDoc,
    setWorkDoc,
    watch,
  ] = useEmployeeContext();

  const [isJoiningDateGreater, setIsJoiningDateGreater] = useState(false);

  const showEsic = watch("workDetail.statutory.employeeStateInsurance.checked");
  const showEpf = watch("workDetail.statutory.employeeProvidentFund.checked");

  const onSubmit = () => {
    let dateOfJoining = getValues("workDetail.currentWorkDetail.dateOfJoining");
    let probationDate = getValues("workDetail.currentWorkDetail.probationDate");

    if (isObject(dateOfJoining))
      dateOfJoining = dateObjToTimestamp(dateOfJoining);
    if (isObject(probationDate))
      probationDate = dateObjToTimestamp(probationDate);

    if (dateOfJoining > probationDate) {
      setIsJoiningDateGreater(true);
    } else {
      setIsJoiningDateGreater(false);
      setValue(
        "workDetail.previousWorkDetail.educationDocuments",
        educationDoc
      );
      setValue("workDetail.previousWorkDetail.workDocuments", workDoc);
      onNextStep();
    }
  };

  const upload = useUpload({
    onSuccess: (res) => {
      const files = res.data.data;
      if (fileType === "education") {
        setEducationDoc(files);
      } else {
        setWorkDoc(files);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  const handleGetSelectedFiles = (files, type) => {
    setFileType(type);
    const fileData = {
      files,
      screen: "employee",
    };
    upload.mutate(fileData);
  };
  const [openDrawer, setOpenDrawer] = useState(false);
  const [docType, setDocType] = useState("");
  const handleSetDocumentDataToDrawer = (type) => {
    setOpenDrawer(true);
    setDocType(type);
  };
  const handleRemoveDoc = (imageID) => {
    setEducationDoc([]);
    const array = docType === "education" ? educationDoc : workDoc;
    const index = array.findIndex((item) => item.image_id === imageID);
    if (index !== -1) {
      array.splice(index, 1);
    }
    if (docType === "education") {
      setEducationDoc(array);
    } else {
      setWorkDoc(array);
    }
  };

  const onLeavesChangedSL = (e) => {
    let newValueSL = getValues("workDetail.currentWorkDetail.leaves.sl.annual");
    let dateOfJoining = getValues("workDetail.currentWorkDetail.dateOfJoining");
    if (!(dateOfJoining instanceof Date))
      dateOfJoining = dateTimestampToObj(dateOfJoining);

    const firstDate = new Date(new Date().getFullYear(), 0, 1);

    if (dateOfJoining.getFullYear() !== firstDate.getFullYear()) {
      setValue("workDetail.currentWorkDetail.leaves.sl.available", newValueSL);
    } else {
      let leavesInOneMonthSL = newValueSL / 12;
      let monthsDifference =
        12 - (dateOfJoining.getMonth() - firstDate.getMonth());
      let totalLeavesSL = Math.floor(leavesInOneMonthSL * monthsDifference);

      setValue(
        "workDetail.currentWorkDetail.leaves.sl.available",
        totalLeavesSL
      );
    }
  };

  const onJoiningDateChanged = (val) => {
    // Probation date to be 6 months more than joining date
    const newDate = val;
    newDate.setMonth(newDate.getMonth() + 6);
    setValue("workDetail.currentWorkDetail.probationDate", newDate);
  };

  const onLeavesChangedCL = (e) => {
    let newValueCL = getValues("workDetail.currentWorkDetail.leaves.cl.annual");
    let dateOfJoining = getValues("workDetail.currentWorkDetail.dateOfJoining");
    if (!(dateOfJoining instanceof Date))
      dateOfJoining = dateTimestampToObj(dateOfJoining);

    const firstDate = new Date(new Date().getFullYear(), 0, 1);
    if (dateOfJoining.getFullYear() !== firstDate.getFullYear()) {
      setValue("workDetail.currentWorkDetail.leaves.cl.available", newValueCL);
    } else {
      let leavesInOneMonthCL = newValueCL / 12;
      let monthsDifference =
        12 - (dateOfJoining.getMonth() - firstDate.getMonth());
      let totalLeavesCL = Math.floor(leavesInOneMonthCL * monthsDifference);

      setValue(
        "workDetail.currentWorkDetail.leaves.cl.available",
        totalLeavesCL
      );
    }
  };

  return (
    <>
      <div>
        {upload?.isLoading && <Spinner />}
        <div className=" space-y-8">
          <div>
            <h1 className="font-semibold text-xl mb-6 underline">
              Current Work Details
            </h1>
            <div className="grid grid-cols-2 gap-6 items-end">
              <Input
                control={control}
                label={"Designation"}
                name={"workDetail.currentWorkDetail.designation"}
                placeholder={"Please enter designation"}
                rules={{
                  required: "Designation is required",
                }}
              />
              {!id && (
                <Select
                  control={control}
                  label={"Department"}
                  name={"workDetail.currentWorkDetail.department"}
                  options={departmentListing}
                  customKey={{
                    label: "departmentName",
                    value: "id",
                  }}
                  rules={{
                    required: "Department is required",
                  }}
                />
              )}
              {/* {!id && ( */}
                <Select
                  control={control}
                  label={"Employment Type"}
                  name={"workDetail.currentWorkDetail.employmentType"}
                  options={employmentList}
                  rules={{
                    required: "Employment Type is required",
                  }}
                  customKey={{
                    label: "name",
                    value: "id",
                  }}
                />
              {/* )} */}
              {!id && (
                <Select
                  control={control}
                  label={"Shift"}
                  name={"workDetail.currentWorkDetail.shift"}
                  options={shiftList}
                  rules={{
                    required: false,
                  }}
                  customKey={{
                    label: "shiftName",
                    value: "id",
                  }}
                />
              )}

              <div>
                <DateInput
                  control={control}
                  label={"Date Of Joining "}
                  name={"workDetail.currentWorkDetail.dateOfJoining"}
                  onChangeFn={(val) => {
                    onJoiningDateChanged(val);
                    onLeavesChangedCL();
                    onLeavesChangedSL();
                  }}
                  rules={{
                    required: "Date Of Joining is required",
                  }}
                />
                {isJoiningDateGreater && (
                  <p className="text-red-600 font-medium block text-sm mt-1">
                    Joining date cannot be greater than probation date
                  </p>
                )}
              </div>

              <DateInput
                control={control}
                label={"Probation Date "}
                name={"workDetail.currentWorkDetail.probationDate"}
              />
            </div>
          </div>
          {isCreation && (
            <>
              <div>
                <h1 className="font-semibold text-xl mb-6 underline">
                  Sick Leaves
                </h1>
                <div className="grid grid-cols-2 gap-6 items-end">
                  <Input
                    control={control}
                    label={"Annual Leaves"}
                    name={"workDetail.currentWorkDetail.leaves.sl.annual"}
                    placeholder={"Please enter annual leaves"}
                    onChangeFn={onLeavesChangedSL}
                    rules={{
                      required: "This filed is required",
                    }}
                  />
                  <Input
                    control={control}
                    label={"Available Leaves"}
                    name={"workDetail.currentWorkDetail.leaves.sl.available"}
                    placeholder={"Please enter available leaves"}
                    rules={{
                      required: "This field is required",
                    }}
                  />
                </div>
              </div>
              <div>
                <h1 className="font-semibold text-xl mb-6 underline">
                  Casual Leaves
                </h1>
                <div className="grid grid-cols-2 gap-6 items-end">
                  <Input
                    control={control}
                    label={"Annual Leaves"}
                    name={"workDetail.currentWorkDetail.leaves.cl.annual"}
                    placeholder={"Please enter annual leaves"}
                    onChangeFn={onLeavesChangedCL}
                    rules={{
                      required: "This filed is required",
                    }}
                  />
                  <Input
                    control={control}
                    label={"Available Leaves"}
                    name={"workDetail.currentWorkDetail.leaves.cl.available"}
                    placeholder={"Please enter available leaves"}
                    rules={{
                      required: "This field is required",
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <div>
            <h1 className="font-semibold text-xl mb-6 underline">
              Previous Work Details
            </h1>
            <div className=" space-y-6">
              <div className="grid grid-cols-2 gap-6 items-end">
                <Input
                  control={control}
                  label={"Education detail"}
                  name={"workDetail.previousWorkDetail.education"}
                  placeholder={"Please enter education detail"}
                  rules={{
                    required: "Education detail is required",
                  }}
                />
                {!id && (
                  <div className="flex items-center space-x-2">
                    <div className="h-10">
                      <FileUploader
                        multiple
                        accept={".pdf, image/png, image/jpeg, image/jpg"}
                        onChange={(files) =>
                          handleGetSelectedFiles(files, "education")
                        }
                      />
                    </div>
                    {educationDoc?.length > 0 && (
                      <Button
                        variant={"warning"}
                        className=""
                        onClick={() =>
                          handleSetDocumentDataToDrawer("education")
                        }
                      >
                        Documents
                      </Button>
                    )}
                  </div>
                )}
              </div>
              <div className="grid grid-cols-2 gap-6 items-end">
                <Input
                  control={control}
                  type="number"
                  label={"Work Experience (in years)"}
                  name={"workDetail.previousWorkDetail.workExperience"}
                  placeholder={"Please enter work experience"}
                  rules={{
                    required: "Work Experience detail is required",
                  }}
                />
                {!id && (
                  <div className="flex items-center space-x-2">
                    <div className="h-10">
                      <FileUploader
                        label="Upload Work Experience Documents"
                        multiple
                        accept={".pdf, image/png, image/jpeg, image/jpg"}
                        onChange={(files) =>
                          handleGetSelectedFiles(files, "work")
                        }
                      />
                    </div>
                    {workDoc?.length > 0 && (
                      <Button
                        variant={"warning"}
                        className=""
                        onClick={() => handleSetDocumentDataToDrawer("work")}
                      >
                        Documents
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className=" mb-6">
              <h1 className="font-semibold text-xl underline">
                Statutory Components
              </h1>
              <small>
                Enable the necessary benefits and tax applicable for this
                employee.
              </small>
            </div>

            <div className=" mt-5">
              <p className=" mb-4 font-semibold">
                <Checkbox
                  control={control}
                  name={"workDetail.statutory.employeeProvidentFund.checked"}
                  label={"Employee Provident Fund"}
                  className={" font-semibold "}
                />
              </p>
              {showEpf && (
                <div className="grid grid-cols-2 gap-6 items-end">
                  <Input
                    control={control}
                    label={"PF Account Number:"}
                    name={
                      "workDetail.statutory.employeeProvidentFund.providentFundNumber"
                    }
                    placeholder={"AA/AAA/0000000/000/0000000"}
                    rules={{
                      required: false,
                    }}
                  />
                  <Input
                    control={control}
                    label={"UAN"}
                    name={"workDetail.statutory.employeeProvidentFund.UAN"}
                    placeholder={"0000"}
                    rules={{
                      required: false,
                    }}
                  />
                </div>
              )}
            </div>
            <div className=" mt-5">
              <p className=" mb-4 font-semibold">
                <Checkbox
                  control={control}
                  name={"workDetail.statutory.employeeStateInsurance.checked"}
                  label={"Employees' State Insurance"}
                  className={" font-semibold "}
                />
              </p>
              {showEsic && (
                <>
                  <div className="grid grid-cols-2 gap-6 items-end">
                    <Input
                      control={control}
                      label={"ESI Insurance Number:"}
                      name={
                        "workDetail.statutory.employeeStateInsurance.insuranceNumber"
                      }
                      placeholder={"0000"}
                      rules={{
                        required: false,
                      }}
                    />
                  </div>
                  <small className=" text-xs text-gray-500">
                    Note: ESI deductions will be made only if the employee’s
                    monthly salary is less than or equal to ₹21,000
                  </small>
                </>
              )}
            </div>
            <div className=" mt-5">
              <Checkbox
                control={control}
                name={"workDetail.statutory.professionalTax"}
                className={"font-semibold"}
                label={"Professional Tax"}
              />
              <small className=" block text-gray-400">{`Note: Professional Tax deductions`}</small>
              <small className=" block text-gray-400">{`Male employee if salary between ₹ 7,500 - ₹ 10,000 - (₹175) will be deducted if salary greater then ₹ 10,000 - (₹200) will be deducted`}</small>
              <small className=" block text-gray-400">{`Female employee if salary between ₹ 7,500 - ₹ 25,000 - (₹0) will be deducted if salary greater then ₹ 25,001 - (₹200) will be deducted`}</small>
            </div>
            <div className=" mt-5">
              <Checkbox
                control={control}
                name={"workDetail.statutory.mlwf"}
                className={"font-semibold"}
                label={"MLWF"}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center my-10 space-x-3 items-center">
          <Button variant={"outline"} onClick={onPreviousStep}>
            <ArrowLeftIcon className="mr-2 w-4 h-4" />
            Previous
          </Button>
          <Button className="" onClick={handleSubmit(onSubmit)}>
            Next
            <ArrowRightIcon className="w-4 h-4 ml-2" />
          </Button>
        </div>
      </div>
      <DocumentsDrawer
        onRemoveDoc={handleRemoveDoc}
        data={docType === "education" ? educationDoc : workDoc}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      />
    </>
  );
};

export default WorkDetailsForm;
