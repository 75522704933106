import * as React from "react";
import { cn } from "../../utility/utils"; 
import { Controller } from "react-hook-form";
import {Label} from '.'

const Input = React.forwardRef(
  ({ className, type, label, control, rules, name, onChangeFn, ...props }, ref) => {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <div className="flex flex-col space-y-2">
              <Label>
                {label}
                {rules?.required && <span className=" text-red-600">*</span>}
              </Label>
              <input
                type={type}
                className={cn(
                  "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
                  className
                )}
                ref={ref}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  onChangeFn?.(e);
                }}
                {...props}
              />
              {error && (
                  <small className="text-red-600 font-medium text-sm">
                    {error.message}
                  </small>
                )}
            </div>
          </>
        )}
      />
    );
  }
);
Input.displayName = "Input";

export default Input;
