import { Drawer } from '@mui/material'
import React from 'react'
import { ArrowLeftIcon } from '@heroicons/react/solid'
import useGet from '../../hooks/read/useGet'
import useReadWithGet from '../../hooks/read/useReadWithGetMethod'
import Moment from 'react-moment'

const SingleResignEmployeeDetail = ({ open, handleCloseDialog, userId }) => {
    const { list: employeeData } = useReadWithGet({
        url: 'getSingleUserResignationDetails',
        initialData: {
            user_id: userId
        }
    })
    return (
        <Drawer anchor={'right'} open={open} onClose={handleCloseDialog}>
            <div className='h-full bg-white' style={{ width: 'calc(100vw - 650px)' }}>
                <div className='border-b border-gray-200 px-8 sticky top-0 bg-white flex items-center justify-between'>
                    <div className=' flex items-center space-x-4'>
                        <ArrowLeftIcon className='w-6 h-6 text-black cursor-pointer' onClick={handleCloseDialog} />
                        <h1 className=' text-3xl font-semibold py-3'>User Details</h1>
                    </div>
                </div>
                <div className='px-8 mt-10'>
                    <div className='grid grid-cols-1 gap-5'>
                        <table className='w-full'>
                            <thead>
                                <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white'>Employee Details</th></tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>First Name</td>
                                    <td className='border py-3 px-4'>{employeeData.data?.data[0]?.first_name}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Last Name</td>
                                    <td className='border py-3 px-4'>{employeeData.data?.data[0]?.last_name}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Email</td>
                                    <td className='border py-3 px-4'>{employeeData.data?.data[0]?.email}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Date Of Birth</td>
                                    <td className='border py-3 px-4'>
                                        <Moment unix format='LL'>
                                            {employeeData.data?.data[0]?.date_of_birth}
                                        </Moment>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Mobile Number</td>
                                    <td className='border py-3 px-4'>{employeeData.data?.data[0]?.phone}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Alternate Number</td>
                                    <td className='border py-3 px-4'>{employeeData.data?.data[0]?.alternate_number}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Date of joining</td>
                                    <td className='border py-3 px-4'>
                                        <Moment unix format='LL'>
                                            {employeeData.data?.data[0]?.date_of_joining}
                                        </Moment>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='font-bold border py-3 px-4'>Date of leaving</td>
                                    <td className='border py-3 px-4'>
                                        <Moment unix format='LL'>
                                            {employeeData.data?.data[0]?.date_of_leaving}
                                        </Moment>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {employeeData.data?.data[0]?.remarks && 
                    <div className='mt-4'>
                        <label className=' roboto label'>Remark</label>
                        <p>{employeeData.data?.data[0]?.remarks}</p>
                    </div>}
                    {employeeData.data?.data[0]?.document && 
                    <div className='mt-10'>
                        <h1 className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white text-center'>Document</h1>
                        <img src={employeeData.data?.data[0]?.document} alt="doc" />
                    </div>}
                </div>
            </div>
        </Drawer>
    )
}

export default SingleResignEmployeeDetail