import usePut from '../put/usePut'
import toast from 'react-hot-toast'

const useMutatePettyCash = ({
    onSuccess
}={}) => {
    const { handleAuth: mutatePettyCash } = usePut({
        url: 'create_update_petty_cash',
        onSuccess: (res) => {
            onSuccess?.()
            toast.success("Record Saved Successfully")
        },
        onError: (err) => {
            //   const {status,data} = err.response
            //   if(status >= 400 || status <= 499){
            //     toast.error(data?.message)
            //   }else if(status >= 500 || status <= 599){
            //     toast.error('Internal server error')
            //   }
        },
        refreshUrl: 'petty_cash_listing'
    })
    const handleSubmitForm = (data) => {
        // console.log(data);
        mutatePettyCash.mutate(data)
    }
    return { mutatePettyCash,handleSubmitForm }
}

export default useMutatePettyCash