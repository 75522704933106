import React from 'react'
import { FolderOpenIcon } from '@heroicons/react/solid'

const HandBookCard = ({
    data,
    onClickEdit,
    onclickDelete,
    onClick,
    role
}) => {
    const {document_name} = data
    return (
        <div onClick={onClick} className=' border rounded-md p-3 bg-white shadow-md cursor-pointer'>
            <FolderOpenIcon className='w-20 h-20 text-yellow-400 mx-auto' />
            <p className=' text-center font-semibold text-sm'>{document_name}</p>
            {role === 'admin' && 
            <div className=' text-center space-x-2 mt-4'>
                <button className='px-5 py-1 rounded bg-blue-600 text-white' onClick={onClickEdit}> Edit</button>
                <button className='px-5 py-1 rounded bg-red-600 text-white' onClick={onclickDelete}> Delete</button>
            </div>}
        </div>
    )
}

export default HandBookCard