import React from "react";
import usePut from "../usePut";

const useResetPassword = ({ onSuccess }) => {
  const { handleAuth: reqObject } = usePut({
    url: "passwordreset",
    onSuccess: (res) => {
      onSuccess?.(res);
    },
  });

  const resetPassword = ({ id, password }) => {
    reqObject.mutate({
      id: id,
      password: password,
    });
  };

  return { reqObject, resetPassword };
};

export default useResetPassword;
