import React from 'react'
import Layout from '../../components/layout/Layout'
import SalaryTab from './SalaryTab'


function Salary({ children }) {

    return (
        <>
            <div className=' mb-20'>
                <div className='sticky top-16 w-full h-full bg-white z-40'>
                    <SalaryTab />
                </div>
                {children}
            </div>
        </>
    )
}

export default Salary