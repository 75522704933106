import React, { useRef, useState } from 'react'
import Layout from '../../components/layout/Layout'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ChevronLeftIcon, XIcon } from '@heroicons/react/solid'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import useRead from '../../hooks/useRead';
import toast, { Toaster } from 'react-hot-toast'
import usePut from '../../hooks/usePut';
import Spinner from '../../components/loader/Spinner';
import { Modal } from '@mui/material';
import InlineLoader from '../../components/loader/InlineLoader';
import { NOTICE_PERIOD_CONSULTANT, NOTICE_PERIOD_EMPLOYEE } from '../../config';
import useGet from '../../hooks/read/useGet';

const ResignEmployee = () => {
  const { id, username, type } = useParams()
  const documentRef = useRef(null)
  const navigate = useNavigate()
  const handleSetNoticePeriodDays = () => {
    return type === 'consultant' ? NOTICE_PERIOD_CONSULTANT : NOTICE_PERIOD_EMPLOYEE
  }
  const initialResignForm = {
    user_id: id,
    date_of_resignation: new Date(),
    date_of_leaving: addDays(new Date(), handleSetNoticePeriodDays()),
    document: '',
    remarks: ''
  }
  const [resignationForm, setResignationForm] = useState(initialResignForm)
  const [resignConfirmationModal, setResignConformationModal] = useState(false)
  // const { list: resignUserDetail } = useGet({
  //   url: 'getUserResignationDetails',
  //   initialData: {
  //     user_id: id
  //   }
  // })
  const { list: resignUserDetail } = useRead({
    url: 'getUserResignationDetails',
    initialData: {
      user_id: id
    }
  })

  const handleChangeData = (value, name) => {
    if (name === 'date_of_resignation') {
      setResignationForm((prev) => {
        return {
          ...prev,
          date_of_resignation: value,
          date_of_leaving: addDays(new Date(value), handleSetNoticePeriodDays()),
        }
      })
      return
    }
    setResignationForm((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }
  const { handleAuth: submitForm } = usePut({
    url: 'userResignation',
    onSuccess: () => {
      toast.success('Employee Resignation Submitted')
      setTimeout(() => {
        navigate('/resigned-employee-details')
      }, 1200)
    },
    onError: () => {
      toast.error('There Was An Error While Submitting The Form')
    }
  })
  const handleSubmitForm = (e) => {
    let data = {
      ...resignationForm,
      date_of_leaving: formatDate(resignationForm.date_of_leaving),
      date_of_resignation: formatDate(resignationForm.date_of_resignation)
    }
    submitForm.mutate(data);
  }
  const formatDate = (date) => {
    // converting date to UNIX
    return Math.floor(date.getTime() / 1000)
  };

  const handleOpenDocumentDialog = () => {
    documentRef.current?.click()
  }
  const handleGetFiles = (e) => {
    let files = e.target.files[0]
    setResignationForm((prev) => {
      return {
        ...prev,
        document: files,
      }
    })
  }
  return (
    <>
      <Toaster />
      {(submitForm.isLoading || resignUserDetail.isLoading) 
      ? <Spinner fullWidth={true} /> 
      : <>
        <div className=' px-4 py-4 flex justify-between sticky bg-white top-16 shadow-lg'>
          <span className='bg-red-100 text-red-700 px-4 py-1 rounded-md font-semibold text-sm'>Resigning: {username}</span>
        </div>
        <div className='px-5 py-5'>
          <div className='grid grid-cols-4 gap-x-4 mt-4'>
          <div className="bg-orange-700 text-white text-center py-5 rounded">
                  <h1 className=" text-2xl font-semibold">{`${resignUserDetail.data?.applicableLeaves.pending_ol_leave || 0}/${resignUserDetail.data?.applicableLeaves.applicable_ol || 0}`}</h1>
                  <h3 className="text-lg font-semibold">Leaves Available</h3>
                </div>
            {/* {type === 'consultant'
              ? <>
                <div className="bg-orange-700 text-white text-center py-5 rounded">
                  <h1 className=" text-2xl font-semibold">{`${resignUserDetail.data?.applicableLeaves.pending_ol_leave}/${resignUserDetail.data?.applicableLeaves.applicable_ol}`}</h1>
                  <h3 className="text-lg font-semibold">OL Available</h3>
                </div>

                <div className="bg-orange-700 text-white text-center py-5 rounded">
                  <h1 className=" text-2xl font-semibold">{`${resignUserDetail.data?.applicableLeaves.pending_dl_leave}/${resignUserDetail.data?.applicableLeaves.applicable_dl}`}</h1>
                  <h3 className="text-lg font-semibold">DL Available</h3>
                </div>
              </>
              : <>
                <div className="bg-orange-700 text-white text-center py-5 rounded">
                  <h1 className=" text-2xl font-semibold">{`${resignUserDetail.data?.applicableLeaves.pending_pl_leave}/${resignUserDetail.data?.applicableLeaves.applicable_pl}`}</h1>
                  <h3 className="text-lg font-semibold">PL Available</h3>
                </div>

                <div className="bg-orange-700 text-white text-center py-5 rounded">
                  <h1 className=" text-2xl font-semibold">{`${resignUserDetail.data?.applicableLeaves.pending_cl_leave}/${resignUserDetail.data?.applicableLeaves.applicable_cl}`}</h1>
                  <h3 className="text-lg font-semibold">CL Available</h3>
                </div>

                <div className="bg-orange-700 text-white text-center py-5 rounded">
                  <h1 className=" text-2xl font-semibold">{`${resignUserDetail.data?.applicableLeaves.pending_sl_leave}/${resignUserDetail.data?.applicableLeaves.applicable_sl}`}</h1>
                  <h3 className="text-lg font-semibold">SL Available</h3>
                </div>
              </>} */}
          </div>
          <div className=' grid grid-cols-4 space-x-5 mt-10 items-end'>
            <div className=''>
              <label className=' roboto label'>Resignation/Termination Date</label>
              <DatePicker
                name='date_of_resignation'
                autoComplete='false'
                className='input'
                dateFormat="dd/MM/yyyy"
                // minDate={new Date()}
                selected={resignationForm.date_of_resignation}
                onChange={(value) => handleChangeData(value, 'date_of_resignation')}
              />
            </div>
            <div className=''>
              <label className=' roboto label'>Leaving Date</label>
              <DatePicker
                name='date_of_leaving'
                autoComplete='false'
                className='input'
                dateFormat="dd/MM/yyyy"
                selected={resignationForm.date_of_leaving}
                minDate={resignationForm.date_of_resignation}
                onChange={(value) => handleChangeData(value, 'date_of_leaving')}
              />
            </div>
            <div className=' flex items-end col-span-2'>
              {/* <button onClick={handleOpenDocumentDialog} className=' bg-yellow-200 text-yellow-700 px-4 py-2 rounded font-semibold'>Upload Document</button> */}
              <input type="file" ref={documentRef} onChange={handleGetFiles} accept="image/jpeg, image/png" />
              {resignationForm.document && 
              <div className=' w-24 h-24 border border-gray-100 rounded p-2'>
                <img src={window.URL.createObjectURL(resignationForm.document)} alt="image" className='w-full h-full object-contain'/>
              </div>}
            </div>
          </div>
          <div className=' mt-4'>
            <label className=' roboto label'>Remark</label>
            <textarea name="remarks" placeholder='Remarks.....' className='input min-h-[10rem]' value={ resignationForm.remarks} onChange={(e) =>  handleChangeData(e.target.value, 'remarks')}></textarea>
          </div>
          <div className='mt-4'>
            <button className=' bg-red-600 text-white px-8 py-2 rounded-md font-semibold' onClick={() => setResignConformationModal(true)}>Submit</button>
          </div>
        </div>
      </>}
      <Modal open={resignConfirmationModal} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
        <div className='w-[30%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%] rounded'>
          <div className='document-wrapper px-4 divide-y'>
            <div className="document-head py-4">
              <div className='flex items-center justify-between'>
                <h3 className=' text-gray-700 text-3xl font-semibold'>Resign/Terminate Employee</h3>
                <div className=' w-6 h-6 cursor-pointer' >
                  <XIcon className=' text-black w-full h-full' onClick={() => setResignConformationModal(false)} />
                </div>
              </div>
            </div>
            <div className="document-body py-4 max-h-96 overflow-auto">
              <p>Are You sure you want to resign/terminate this employee</p>
            </div>
            <div className="document-foot space-x-4 py-4">
              <button disabled={submitForm.isLoading} className=' py-2 px-4 rounded font-semibold bg-[color:var(--color1)] text-white' onClick={handleSubmitForm}>
                <div className=' flex items-center justify-center space-x-3'>
                  <span>Resign</span>
                  {submitForm.isLoading && <InlineLoader />}

                </div>
              </button>
              <button className=' py-2 px-4 rounded font-semibold border border-[color:var(--color1)]' onClick={() => setResignConformationModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      </Modal>
    </>

  )
}

export default ResignEmployee