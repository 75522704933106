import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import spinner from "../../assets/images/spinner.gif";
import { Modal } from "@mui/material";
import { PencilAltIcon, TrashIcon, XIcon } from "@heroicons/react/solid";
import Radio from "../../components/radio/Radio";
import {
  deleteHolidayForm,
  postHolidayForm,
  updateHolidayForm,
} from "../../api/holiday";
import Moment from "react-moment";
import useRead from "../../hooks/useRead";
import { useSearchParams } from "react-router-dom";
import Pagination from "../../components/pagination";
import { useQueryClient } from "@tanstack/react-query";
import useGet from "../../hooks/read/useGet";
import DatePicker from "react-datepicker";
import Cookies from "universal-cookie";
import { ROLE } from "../../config";

const cookies = new Cookies();

let IS_REQUIRED = true;
function NewLeaveDetail({ id, year }) {
  const [pageNo, setPageNo] = useState(0);
  // console.log(year);

  const [role, setRole] = useState("");
  useEffect(() => {
    setRole(cookies.get(ROLE));
  }, []);
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialState = {
    holiday_type: "single",
    from_date: "",
    to_date: "",
    title: "",
    employment_type: [],
  };
  /**
   * Work around for the date is converted to year only
   * two states are used for this one foe saving the date object and one for only year will be sent to backend
   */
  let initialHolidayData = {
    limit: 10,
    search_query: searchParams.get("search_query") || "",
    page_no: 0,
    month_year: year?.getFullYear(),
    user_id: id,
  };
  let initialHolidayFilterData = {
    limit: 10,
    search_query: searchParams.get("search_query") || "",
    page_no: 0,
    user_id: id,
    month_year: year?.getFullYear(),
  };
  const [holidayId, setHolidayId] = useState("");
  const [filterData, setFilterData] = useState(initialHolidayFilterData);
  const [isLoading, setIsLoading] = useState(false);
  const [holidayData, setHolidayData] = useState(initialState);
  const [holidayModal, setHolidayModal] = useState(false);
  const [isAddingData, setAddingData] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openHolidayEditModal, setOpenHolidayEditModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    list: holidayList,
    paramsObject,
    setGetListParams,
  } = useGet({
    url: "getUtilizedLeaveListing",
    method: "get",
    initialData: initialHolidayData,
  });

  const handleHolidayFormInputs = (e) => {
    let value = e.target.value;
    let data = {
      ...holidayData,
      [e.target.name]: value,
    };
    setHolidayData(data);
  };

  // const { list: employmentList } = useRead({
  //   url: "employment_listing",
  //   initialData: {
  //     limit: 50,
  //     search_query: "",
  //   },
  // });

  const handleSubmitFormData = (e) => {
    setAddingData(true);
    e.preventDefault();
    postHolidayForm(holidayData)
      .then((res) => {
        if (res.status === 200) {
          // handleFetchHoliday()
          queryClient.invalidateQueries("holidaylist");
          setAddingData(false);
          setHolidayModal(false);
          setHolidayData(initialState);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancelForm = () => {
    setHolidayModal(false);
    setHolidayData(initialState);
    setOpenHolidayEditModal(false);
  };

  const handleOpenDeleteModal = (id) => {
    setHolidayId(id);
    setDeleteConfirmationModal(true);
  };
  const handleDeleteLeave = () => {
    setIsDeleting(true);
    deleteHolidayForm(holidayId)
      .then((res) => {
        if (res.status === 200) {
          setDeleteConfirmationModal(false);
          // handleFetchHoliday()
          queryClient.invalidateQueries("holidaylist");
          setIsDeleting(false);
        }
      })
      .catch((err) => {
        alert("Something went wrong!");
        setIsDeleting(false);
      });
  };

  const handleGetSingleLeaveData = (id) => {
    setHolidayData(holidayList.data.data?.find((data) => data.id === id));
    setOpenHolidayEditModal(true);
  };

  const handleEditHoliday = (e) => {
    setIsUpdating(true);
    e.preventDefault();
    updateHolidayForm(holidayData)
      .then((res) => {
        if (res.status === 200) {
          // handleFetchHoliday()
          queryClient.invalidateQueries("holidaylist");
          setOpenHolidayEditModal(false);
          setHolidayData(initialState);
          setIsUpdating(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsUpdating(false);
      });
  };

  const handleCheckValue = (e, item) => {
    let checked = e.target.checked;
    if (checked) {
      setHolidayData((prev) => {
        return {
          ...prev,
          employment_type: [...holidayData.employment_type, item.id],
        };
      });
    } else {
      let checkedId = holidayData.employment_type.filter((x) => x !== item.id);
      setHolidayData((prev) => {
        return {
          ...prev,
          employment_type: checkedId,
        };
      });
    }
  };

  const handlePageClick = (val) => {
    let data = {
      ...paramsObject,
      page_no: val.selected,
    };
    setPageNo(val.selected);
    setGetListParams(data);
    setSearchParams(data);
  };

  /**
   * Work around for the date is converted to year only
   * two states are used for this one foe saving the date object and one for only year will be sent to backend
   */
  const handleSetYear = (value) => {
    let data = {
      ...paramsObject,
      month_year: new Date(value).getFullYear(),
    };
    let dataForSearchParams = {
      ...paramsObject,
      month_year: value,
    };
    setGetListParams(data);
    setSearchParams(dataForSearchParams);
    setFilterData(dataForSearchParams);
  };
  return (
    <>
      <div className="flex items-end justify-between px-4">
        {/* <div className=" lg:basis-[25%]">
          <label className=" roboto p-2 label">Select Year</label>
          
          <DatePicker
            name="month_year"
            autoComplete="false"
            className="input"
            selected={filterData.month_year}
            showYearPicker
            dateFormat="yyyy"
            onChange={handleSetYear}
          />
        </div> */}
        {/* {role === "employee" || role === "consultant" ? null : (
          <div className="text-end">
            <button
              className=" bg-[color:var(--color1)] text-white rounded px-4 py-4 text-lg"
              onClick={() => setHolidayModal(true)}
            >
              Add holiday
            </button>
          </div>
        )} */}
      </div>
      {isLoading ? (
        <div className=" w-16 h-16 mx-auto mt-8">
          <img src={spinner} alt="spinner" className="w-full h-full" />
        </div>
      ) : (
        <div class="max-w-full overflow-x-auto px-4 mt-8">
          {holidayList.data?.data?.length <= 0 ? (
            <div className="text-center">
              <p className=" text-gray-700 text-3xl">No Data Found</p>
            </div>
          ) : (
            <>
              <table class="table-auto w-full">
                <thead>
                  <tr class=" bg-[color:var(--color1)] text-center">
                    <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent hidden lg:table-cell">
                      Sr.No
                    </th>
                    <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Utilised Date
                    </th>
                    <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Utilised Type
                    </th>
                    <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Status
                    </th>
                    {/* <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      From - To Date
                    </th>
                    {role === "employee" || role === "consultant" ? null : (
                      <>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                          Applicable To
                        </th>
                      </>
                    )} */}
                  </tr>
                </thead>
                <tbody>
                  {holidayList.data?.data?.map((data, idx) => {
                    const {
                      id,
                      title,
                      from_date,
                      to_date,
                      utilized_type,
                      utilized_date,
                      applicable,
                      status,
                    } = data;
                    return (
                      <tr key={idx}>
                        <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] hidden lg:table-cell">
                          {pageNo * initialHolidayData?.limit + idx + 1}
                        </td>
                        <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                          {utilized_date}
                        </td>
                        <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                          {utilized_type === "half_day" && <p>Half Day</p>}
                          {utilized_type === "full_day" && <p>Full Day</p>}
                        </td>
                        <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                          {status}
                        </td>
                        {/* <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                          <Moment format="ll">{from_date}</Moment>
                          {holiday_type === "multiple" && (
                            <>
                              {" "}
                              - <Moment format="ll">{to_date}</Moment>
                            </>
                          )}
                        </td>
                        {role === "employee" || role === "consultant" ? null : (
                          <>
                            <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] capitalize">
                              {applicable?.toLowerCase()}
                            </td>
                          </>
                        )} */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="px-5 mt-5">
                <Pagination
                  currentPage={+paramsObject.page_no}
                  lengthofItems={holidayList.data?.total_count}
                  limit={paramsObject.limit}
                  onPageChange={handlePageClick}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default NewLeaveDetail;
