import React from 'react'
import LoanForm from '../../../components/sections/loan/LoanForm'
import { useParams } from 'react-router-dom'

const AddLoan = () => {
  const {username } = useParams()
  return (
    <div className='px-6'>
      <h2 className=' text-xl font-semibold mb-5 underline'>Loan Record for {username}</h2>
      <LoanForm/>
    </div>
  )
}

export default AddLoan