import { XIcon, PlusIcon } from '@heroicons/react/solid'
import { Modal } from '@mui/material'
import { useForm } from "react-hook-form";
import { Button, Input, Select } from '../../components/ui'
import { RxReload } from "react-icons/rx";
import usePut from '../../hooks/put/usePut';
import toast from 'react-hot-toast';

const AddDepartment = ({ open, onClose, shiftData }) => {
    const { control, handleSubmit,reset } = useForm();
    const {handleAuth: createDepartment} = usePut({
        url: 'department_create',
        onSuccess: (res) => {
          onClose()
          toast.success("Department Created Successfully")
          reset(); 
        },
        onError: (err) => {
          const {status,data} = err.response
          if(status >= 400 || status <= 499){
            toast.error(data?.message)
          }else if(status >= 500 || status <= 599){
            toast.error('Internal server error')
          }
        },
        refreshUrl: 'department_listing'
      })
    const handleSaveDepartment = (data) => {
        createDepartment.mutate(data)
    }
    return (
        <Modal open={open} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
            <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]'>
                <div className='document-wrapper px-4 divide-y'>
                    <div className="document-head py-4">
                        <div className='flex items-center justify-between'>
                            <h3 className=' text-gray-700 text-3xl font-semibold'>Add Department</h3>
                            <div className=' w-6 h-6 cursor-pointer' onClick={onClose}>
                                <XIcon className=' text-black w-full h-full' />
                            </div>
                        </div>
                    </div>
                    <div className="document-body py-4 max-h-96 overflow-auto">
                        <form className=' space-y-5'>
                            <Input
                                control={control}
                                label={"Department Name"}
                                name={"department_name"}
                                placeholder={"Please enter department name"}
                                rules={{
                                    required: "Department name is required",
                                }}
                            />

                            <Select
                                control={control}
                                label={"Shift"}
                                name={"shift_id"}
                                options={shiftData}
                                rules={{
                                    required: "Shift is required",
                                }}
                                customKey={{
                                    label: "shiftName",
                                    value: "id",
                                }}
                            />

                        </form>
                    </div>
                    <div className="document-foot space-x-4 py-4">
                        <div className="grid grid-cols-4 gap-3">
                            <Button disabled={createDepartment.isLoading} onClick={handleSubmit(handleSaveDepartment)}>
                                Save
                                {createDepartment.isLoading && <RxReload className=' ml-2 w-4 h-4'/>}
                            </Button>
                            <Button variant={'outline'} onClick={onClose}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AddDepartment