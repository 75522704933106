import { LogoutIcon, ArrowLeftIcon, MenuAlt4Icon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import profile from '../assets/images/profile.jpg'
import "./header.css";
import Dropdown from './dropdown/Dropdown';
import { useNavigate, useLocation, Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import { COMPANY_NAME, ROLE, TOKEN_NAME, USERNAME } from '../config';
import { Button } from './ui';






const cookies = new Cookies
function Header({ onClick }) {
    const { pathname } = useLocation()
    const [isShowDropDown, setIsShowDropDown] = useState(false)
    const navigate = useNavigate();

    const handleLogout = () => {
        cookies.remove(TOKEN_NAME)
        cookies.remove(ROLE)
        cookies.remove(USERNAME)
        // localStorage.clear();
        navigate('/login');
    };
    const [name, setName] = useState('')
    useEffect(() => {
        setName(cookies.get("_am_name"))
    }, [])

    return (

        <>
            <div className=" text-black bg-gray-50  h-16 px-4 shadow fixed top-0 mb-4 z-50 w-full lg:w-[calc(100%-var(--application-bar-left))] lg:left-[var(--application-bar-left)]">
                <div className=" flex items-center justify-between h-full">
                    <div className='flex items-center space-x-4'>
                        {pathname !== '/' &&
                            <Button size={'icon'} variant={'ghost'} onClick={() => navigate(-1)}>
                                <ArrowLeftIcon className='w-6 h-6' />
                            </Button>}
                        <div className=' block lg:hidden'>
                            <p className=' text-center  text-3xl text-primary font-bold roboto'><Link to={"/"}>{COMPANY_NAME}</Link></p>
                        </div>
                    </div>
                    <div className='block lg:hidden'>
                        <Button size={'icon'} variant={'ghost'} onClick={onClick}>
                            <MenuAlt4Icon className='w-6 h-6' />
                        </Button>
                    </div>
                    <div className="relative hidden lg:block">
                        <div onClick={() => setIsShowDropDown(!isShowDropDown)} className=" cursor-pointer flex items-center">
                            <img src={profile} alt="profile" className=' h-10 rounded-full' />
                            <p className=' roboto ml-4 font-semibold'>{name}</p>
                        </div>
                        {
                            isShowDropDown ? <Dropdown show={isShowDropDown} className={' top-12 left-[-4rem] w-[7.5rem] bg-[color:var(--color1)] px-2 py-3 rounded-l'}>
                                <div onClick={handleLogout}>
                                    <ul className=' space-y-4'>
                                        <li className='flex text-white cursor-pointer'>
                                            <LogoutIcon className=' w-6' />
                                            <span className=' ml-3'>Logout</span>
                                        </li>
                                    </ul>
                                </div>
                            </Dropdown> : null
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default Header