import useGet from '../read/useGet'

const useGetSingleData = ({
    id
}) => {
    const initialState = {
        id
    }
    const { list: responseData } = useGet({
        url: 'fetch_single_petty_cash',
        initialData: initialState
    })
    return { responseData }
}

export default useGetSingleData