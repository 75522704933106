import React, { useState } from 'react'
import { PencilAltIcon, PlusIcon, XIcon } from '@heroicons/react/solid'
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Modal } from '@mui/material'
import AddDepartment from './AddDepartment'
import { Button } from '../../components/ui'
import useGet from '../../hooks/read/useGet';
import useRead from "../../hooks/useRead";
import { Toaster } from "react-hot-toast";
import Spinner from "../../components/loader/Spinner";
import EditDepartment from './EditDepartment';

function Department() {
  const [openDepartmentModal, setOpenDepartmentModal] = useState(false)
  const [openDepartmentAddModal, setOpenDepartmentAddModal] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedDepartment,setSelectedDepartment] = useState({})
  const initialDepartmentState = {
    limit: 50,
    search_query: searchParams.get('search_query') || '',
    page_number: searchParams.get('page_number') || 0,
  }
  const { list: shiftList } = useRead({
    url: "shift_listing",
    method: 'GET',
    initialData: {
      limit: 50,
      search_query: "",
    },
  });
  const { list: departmentListing } = useGet({
    url: 'department_listing',
    initialData: initialDepartmentState
  })

  const handleOpenEditModal = (id) => {
    let departmentData = departmentListing?.data?.data
    let filteredDepartment = departmentData.find(x => x.id === id)
    setSelectedDepartment(filteredDepartment)
    setOpenDepartmentModal(true)
  }
  return (
    <>
      {(shiftList.isLoading || departmentListing.isLoading) && <Spinner />}
      <Toaster />
      <div className='px-4 flex items-center justify-end'>
        <Button onClick={() => setOpenDepartmentAddModal(true)}>
          <PlusIcon className='w-4 h-4 mr-2' />
          Add Department
        </Button>
      </div>
      <div class="max-w-full overflow-x-auto px-4 mt-8">
        <table class="table-auto w-full">
          <thead>
            <tr class=" bg-[color:var(--color1)] text-center">
              <th class="w-1/6  text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Sr.no</th>
              <th class="w-1/6  text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Department Name</th>
              <th class="w-1/6  text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-r border-transparent">Modify</th>
            </tr>
          </thead>
          <tbody>
            {departmentListing.data?.data?.map((item, idx) => {
              const { departmentName, id } = item
              return <tr>
                <td class="text-center text-dark font-medium text-sm py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{idx + 1}</td>
                <td class="text-center text-dark font-medium text-sm py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{departmentName}</td>
                <td class="text-center text-dark font-medium text-sm py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                  <div className='text-center mx-auto w-6 h-6 cursor-pointer' onClick={() => handleOpenEditModal(id)}>
                    <PencilAltIcon className=' text-gray-600 w-full h-full hover:text-blue-600' />
                  </div>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
      {openDepartmentModal && <EditDepartment open={openDepartmentModal} shiftData={shiftList?.data?.data} onClose={() => setOpenDepartmentModal(false)} selectedDepartment={selectedDepartment}/>}
      {openDepartmentAddModal && <AddDepartment shiftData={shiftList?.data?.data} open={openDepartmentAddModal} onClose={() => setOpenDepartmentAddModal(false)} />}
    </>
  )
}

export default Department