import React from 'react'
import Layout from '../../components/layout/Layout'
import LeaveApplicationForm from './LeaveApplicationForm'

function LeaveApplication() {
   return (
      <>
         <>
            <LeaveApplicationForm />
         </>
      </>
   )
}

export default LeaveApplication