import { useForm } from "react-hook-form"
import { Button } from "../../ui"
import { RxReload } from "react-icons/rx"
import { useParams } from "react-router-dom"
import useMutateLoan from "../../../hooks/Loan/useMutateLoan"
import { handleCalculateEmi } from "../../../utility"
import { useEffect } from "react"

const LoanForm = ({formData}) => {
    const { uid, loadId } = useParams()
    const { register, handleSubmit, setValue, watch } = useForm({
        defaultValues: {
            amount: '',
            id: loadId || '', // loan id
            user_id: uid,
            emi_duration: '',
            from_date: '',
            actual_end_date: '',
            estimated_end_date: '',
            loan_status: 'ongoing', // ongoing | paid
            remark: ''
        }
    })
    const { mutateLoan, handleSubmitForm } = useMutateLoan()
    useEffect(() => {
        if(formData){
            Object.keys(formData).forEach((key) => {
                setValue(key,formData[key])
            })
        }
    },[formData])
    return (
        <>
            <div className='grid grid-cols-2 gap-4'>
                <div>
                    <label className='label'>Loan Amount: </label>
                    <input placeholder='' className='input' {...register("amount", { required: true })} />
                </div>
                <div>
                    <label className='label'>Start Date: </label>
                    <input placeholder='' type="date" className='input' {...register("from_date", { required: true })} />
                </div>
                <div>
                    <label className='label'>No of Months: </label>
                    <input placeholder='' className='input' {...register("emi_duration", { required: true })} />
                </div>
                <div>
                    <label className='label'>Actual End Date: </label>
                    <input placeholder='' type="date" className='input' {...register("actual_end_date", { required: false })} />
                </div>
                <div>
                    <label className='label'>Estimated End Date: </label>
                    <input placeholder='' type="date" className='input' {...register("estimated_end_date", { required: false })} />
                </div>
                <div>
                    <label className='label'>EMI Amount: </label>
                    <input disabled placeholder='' type="text" className='input' value={handleCalculateEmi(watch('amount'),watch('emi_duration'))} />
                </div>
                <div>
                    <label className='label'>Loan Status: </label>
                    <select className='input' {...register("loan_status", { required: false })}>
                        <option value="ongoing">Ongoing</option>
                        <option value="paid">Paid</option>
                    </select>
                </div>
                <div className=' col-span-2'>
                    <label className='label'>Remark: </label>
                    <textarea {...register("remark", { required: true })} className='input min-h-[10rem]' />
                </div>
            </div>
            <div className=' text-center mt-10'>
                <Button disabled={mutateLoan.isLoading} className={'px-4 py-2'} onClick={handleSubmit(handleSubmitForm)}>
                    Save Record
                    {mutateLoan.isLoading && <RxReload className=' ml-2 w-4 h-4'/>}
                </Button>
            </div>
        </>
    )
}

export default LoanForm