import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout/Layout'
import HandBookCard from './HandBookCard'
import AddHandBook from './AddHandBook'
import EditHandBook from './EditHandBook'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useGet from '../../hooks/read/useGet'
import Pagination from '../../components/pagination'
import DeleteHandBook from './DeleteHandBook'
import PrimaryLoader from '../../components/common/PrimaryLoader'

import Cookies from 'universal-cookie';
import { ROLE } from '../../config'
const cookies = new Cookies
const HandBook = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [role, setRole] = useState('')
    useEffect(() => {
        setRole(cookies.get(ROLE))
    }, [])
    const navigate = useNavigate()
    let initialData = {
        page_number: searchParams.get('page_number') || 0,
        limit: searchParams.get('limit') || 50,
        search_query: searchParams.get('search_query') || '',
    }
    const [isOpenAddHandBook, setIsOpenAddHandBook] = useState(false)
    const [isOpenEditHandBook, setIsOpenEditHandBook] = useState(false)
    const [isOpenDeleteHandBook, setIsOpenDeleteHandBook] = useState(false)
    const [documentData, setDocumentData] = useState({})
    const [handBookId, setHandBookId] = useState('')
    const handleClickAddDocument = () => {
        setIsOpenAddHandBook(true)
    }
    const handleEditHandBook = (e, id) => {
        e.stopPropagation()
        setHandBookId(id)
        let data = list.data.data?.find(x => x.id === id)
        setDocumentData(data)
        setIsOpenEditHandBook(true)
    }
    const handleDeleteHandBook = (e, id) => {
        e.stopPropagation()
        setHandBookId(id)
        setIsOpenDeleteHandBook(true)
    }
    const { list, paramsObject, setGetListParams } = useGet({
        url: 'company_documents_list',
        initialData
    })
    const handleSearch = (e) => {
        let value = e.target.value
        let data = {
            ...paramsObject,
            search_query: value,
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            page_number: val.selected
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    const navigatePage = (link) => {
        window.open(link, "_blank")
    }
    return (
        <>
            <div className='px-4'>
                {(role === 'admin') && <div>
                    <button onClick={handleClickAddDocument} className=' px-4 bg-color1 text-white rounded py-2'>Add Document</button>
                </div>}
                <div className='pt-4 w-[40%]'>
                    <input type="search" value={paramsObject.search_query} placeholder='Search By document name...' name='search_query' className='input' onChange={handleSearch} />
                </div>
                {list.isLoading
                    ? <div className=' flex items-center justify-center mt-10'> <PrimaryLoader /></div>
                    : <div className='mt-10'>
                        <div className="grid grid-cols-5 gap-5">
                            {list.data?.data.map((item) => {
                                return <HandBookCard
                                    key={item.id}
                                    data={item}
                                    onClick={() => navigatePage(item.media_url)}
                                    onClickEdit={(e) => handleEditHandBook(e, item.id)}
                                    onclickDelete={(e) => handleDeleteHandBook(e, item.id)}
                                    role={role}
                                />
                            })}
                        </div>
                        <div className='px-5 mt-5'>
                            <Pagination
                                currentPage={+paramsObject.page_number}
                                lengthofItems={list.data?.total_count}
                                limit={paramsObject.limit}
                                onPageChange={handlePageClick}
                            />
                        </div>
                    </div>}
            </div>
            {isOpenAddHandBook && <AddHandBook open={isOpenAddHandBook} handleCloseDialog={() => setIsOpenAddHandBook(false)} />}
            {isOpenEditHandBook && <EditHandBook data={documentData} id={handBookId} open={isOpenEditHandBook} handleCloseDialog={() => setIsOpenEditHandBook(false)} />}
            <DeleteHandBook id={handBookId} open={isOpenDeleteHandBook} onClose={() => setIsOpenDeleteHandBook(false)} />
        </>
    )
}




export default HandBook