import useGet from "../read/useGet";

const useGetTransactions = ({pettyCashId, onSuccess}) => {
  const { list: responseData } = useGet({
    url: "fetch_single_petty_cash_transaction",
    initialData: { petty_cash_id: pettyCashId },
    onSuccess: (res) => {
        onSuccess?.(res);
    }
  });

  return { responseData };
};

export default useGetTransactions;
