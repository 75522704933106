import React from 'react';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Label from './label';
import { cn } from '../../utility/utils';

const DateInput = ({
    
    className,
    label = 'label',
    rules,
    name,
    control,
    maxDate,
    onChangeFn,
    props
}) => {
    // (value instanceof Date) ? new Date()  : new Date(value * 1000)
   const getConvertedValue = (value)=>{
    if (!value){
        return new Date()
    }
    if(value instanceof Date){
        return value
    }
    return new Date(value * 1000)
   }
    return (
        <>
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                        <div className="flex flex-col space-y-2">
                            <Label>
                                {label}
                                {rules?.required && <span className=" text-red-600">*</span>}
                            </Label>
                            <div>
                                <DatePicker
                                    selected={getConvertedValue(value) }
                                    onChange={(date) => {
                                        onChange(date);
                                        onChangeFn?.(date);
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    className={cn(
                                        "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
                                        className
                                    )}
                                    maxDate={maxDate}
                                    {...props}
                                />
                            </div>
                            {error && (
                                <small className="text-red-600 font-medium block pt-1 text-sm">
                                    {error.message}
                                </small>
                            )}
                        </div>
                    </>
                )}
            />
        </>
    )
}

export default DateInput