import { useSearchParams } from 'react-router-dom';
import useGet from '../read/useGet'


const usePettyCashListing = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialState = {
        limit: 50,
        search_query: searchParams.get('search_query') || '',
        page_number: searchParams.get('page_number') || 0,
    }
    const { list: responseData, paramsObject, setGetListParams } = useGet({
        url: 'petty_cash_listing',
        initialData: initialState
    })
    const handleSearch = (e) => {
        let value = e.target.value
        setGetListParams((prev) => {
            return {
                ...prev,
                page_number: 0,
                search_query: value
            }
        })
    }
    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            page_number: val.selected
        }
        setGetListParams(data)
    }
    return { responseData, paramsObject, handlePageClick, handleSearch }
}

export default usePettyCashListing