import { useState } from "react"
import { useMutation, } from "@tanstack/react-query";
import Cookies from "universal-cookie";
import { TOKEN_NAME } from "../../config";
import axios from "../../api/axios";
import { generateURL } from "../../utility";
const cookies = new Cookies();


// THIS HOOK IS USE TO CALL PUBLIC API ONLY
const useAwaitGet = ({
    url, onSuccess, onError
}) => {
    const handleSubmitData = useMutation({
        mutationFn: (data) => getUsers(data),
        retry: 1,
        onSuccess: (data) => {
            onSuccess?.(data)
        },
        onError: (err) => {
            onError?.(err)
        },
        onSettled: () => {
            // onSettled?.()
        }
    })

    const getUsers = async (paramsObject) => {
        let token = cookies.get(TOKEN_NAME);
        const response = await axios({
            method: "GET",
            url: `/${url}${generateURL(paramsObject)}`,
            headers: { Authorization: token ? `Bearer ${token}` : '', }
        })
        return response.data
    }

    return handleSubmitData
}

export default useAwaitGet