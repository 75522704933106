import { useNavigate } from 'react-router-dom';
import usePut from '../put/usePut'
import toast from 'react-hot-toast'

const useMutateLoan = () => {
    const navigate = useNavigate();
    const { handleAuth: mutateLoan } = usePut({
        url: 'create_update_employee_loan',
        onSuccess: (res) => {
            toast.success("Record Saved Successfully")
            navigate(-1)
        },
        onError: (err) => {
            //   const {status,data} = err.response
            //   if(status >= 400 || status <= 499){
            //     toast.error(data?.message)
            //   }else if(status >= 500 || status <= 599){
            //     toast.error('Internal server error')
            //   }
        },
        refreshUrl: 'employee_loan_listing'
    })
    const handleSubmitForm = (data) => {
        mutateLoan.mutate(data)
    }
    return { mutateLoan,handleSubmitForm }
}

export default useMutateLoan