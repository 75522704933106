import React, { useState } from 'react'
import { PlusIcon } from '@heroicons/react/solid'
import AdvanceShiftEditing from './AdvanceShiftEditing'
import { formatTime } from '../../utility'
import usePut from '../../hooks/usePut'
import Modals from '../../components/modal/Modals'
import toast, { Toaster } from 'react-hot-toast'
import useGet from '../../hooks/read/useGet'
import useAwaitGet from '../../hooks/read/useAwaitGet'
import { Button } from '../../components/ui'
import Spinner from '../../components/loader/Spinner'


const Shifts = () => {
    let initialData = {
        limit: 50,
        searchQuery: '',
    }
    const [isOpenAdvanceShift, setIsOpenAdvanceShift] = useState(false)
    const [isOpenShiftDelete, setIsOpenShiftDelete] = useState(false)
    const [selectedShiftId, setSelectedShiftId] = useState(null)
    const [mode, setMode] = useState(null)

    const getSingleShift = useAwaitGet({
        url: 'get_single_shift',
        onSuccess: () => {
            setIsOpenAdvanceShift(true)
        },
        onError:(err) => {
            const {status,data} = err.response || {}
            if(status >= 400 || status <= 499){
                toast.error(data?.message)
            }else{
                toast.error('Server error')
            }
        }
    })
    const { handleAuth: deleteShift } = usePut({
        url: 'shift_disabled',
        refreshUrl: 'shift_listing',
        onSuccess: () => {
            setIsOpenShiftDelete(false)
            toast.success('Shift Deleted Successfully')
        },
        onError: () => {
            setIsOpenShiftDelete(false)
            toast.error('There Was An Error While Deleting Please Try Again Later')
        }
    })
    const handleDelete = () => {
        let data = { id: selectedShiftId }
        deleteShift.mutate(data)
    }
    const handleCreateShift = () => {
        setMode(null)
        setIsOpenAdvanceShift(true)
    }
    const { list } = useGet({
        url: "shift_listing",
        initialData,
        method: 'GET'
    })
    const handleDeleteModal = (id) => {
        setSelectedShiftId(id)
        setIsOpenShiftDelete(true)
    }
    const handleClickedAdvanceShift = (id) => {
        let data = { id }
        setMode('edit')
        getSingleShift.mutate(data)
    }

    return (
        <>
            {(list.isLoading || getSingleShift.isLoading) && <Spinner/>}
            <Toaster />
            <div className='p-5'>
                <div className=' flex items-center justify-end'>
                    <Button className={'px-4 py-2'} onClick={handleCreateShift}>
                        <PlusIcon className='w-4 h-4 mr-2' />
                        Add New Shift
                    </Button>
                </div>
                <div className="grid grid-cols-3 gap-5 mt-5">
                    {list.data?.data.map((shiftData, shiftIdx) => {
                        const { id, shiftName, shiftInTime, shiftOutTime, workingTypeMode, working_hours, maximum_working_hours, minimum_working_hours } = shiftData
                        return <div key={shiftIdx} className='w-full rounded-md border border-gray-300 p-4'>
                            <div>
                                <p className=''>
                                    <label className='text-gray-700 font-semibold text-lg'>Shift Name: </label>
                                    <span>{shiftName}</span>
                                </p>
                                {workingTypeMode === 'lenient'
                                    ? <div className=''>
                                        <p>
                                            <label className='text-gray-700 font-semibold text-xs'>Maximum working hour: </label>
                                            <span className='text-gray-700 font-semibold text-xs'>{maximum_working_hours} hr</span>
                                        </p>
                                        <p>
                                            <label className='text-gray-700 font-semibold text-xs'>Minimum working hour: </label>
                                            <span className='text-gray-700 font-semibold text-xs'>{minimum_working_hours} hr</span>
                                        </p>
                                    </div>
                                    :
                                    <p>
                                        <label className='text-gray-700 font-semibold text-lg'>Shift Time: </label>
                                        <span className=''>
                                            <span className='mx-1'>
                                                {formatTime(shiftInTime)}
                                            </span>
                                            -
                                            <span className='mx-1'>
                                                {formatTime(shiftOutTime)}
                                            </span>
                                        </span>
                                    </p>
                                }
                                <div className=' space-x-2 mt-4'>
                                    <Button variant={'link'} className={'px-0 py-2'} onClick={() => handleClickedAdvanceShift(id)}>
                                        <span className=' text-primary font-semibold text-xs'>
                                            View Shift Detail
                                        </span>
                                    </Button>
                                    {/* <Button variant={'ghost'} className={'px-4 py-2'} onClick={() => handleDeleteModal(id)}>
                                        <span className=' text-red-600 font-semibold'>
                                            Delete
                                        </span>
                                    </Button> */}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            {/* {console.log(getSingleShift.data?.data[0])} */}
            {isOpenAdvanceShift && <AdvanceShiftEditing mode={mode} shiftData={getSingleShift.data?.data[0]} open={isOpenAdvanceShift} onClose={() => setIsOpenAdvanceShift(false)} />}
            <Modals show={isOpenShiftDelete}>
                <div>
                    <p className=' roboto text-center font-bold text-2xl text-gray-700'>Are you sure you want to delete this shift?</p>
                    <div className=" mt-12 space-x-3 text-center ">
                        <button className='px-4 py-2 rounded-md font-semibold bg-red-200 text-red-700 border-red-800' onClick={handleDelete}>Yes</button>
                        <button className='px-4 py-2 rounded-md font-semibold  border' onClick={() => setIsOpenShiftDelete(false)}>Cancel</button>
                    </div>
                </div>
            </Modals>
        </>
    )
}

export default Shifts