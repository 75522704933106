import React from 'react'
import useGet from '../read/useGet'

const useGetExpencesTypeList = () => {
    const { list: responseData } = useGet({
        url: 'get_expenses_type_listing',
        initialData: {}
    })
    return { responseData }
}

export default useGetExpencesTypeList