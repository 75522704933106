import { useNavigate } from 'react-router-dom';
import usePut from '../put/usePut'
import toast from 'react-hot-toast'

const useDeleteLoan = ({
  onSuccess
}) => {
  const navigate = useNavigate();
  const { handleAuth: mutateLoan } = usePut({
    url: 'delete_employee_loan',
    onSuccess: (res) => {
        onSuccess?.()
        navigate(-1)
        toast.success("Record deleted Successfully")
    },
    onError: (err) => {
        //   const {status,data} = err.response
        //   if(status >= 400 || status <= 499){
        //     toast.error(data?.message)
        //   }else if(status >= 500 || status <= 599){
        //     toast.error('Internal server error')
        //   }
    },
    refreshUrl: 'employee_loan_logs_listing'
})
const handleDelete = (data) => {
    mutateLoan.mutate(data)
}
return { mutateLoan,handleDelete }
}

export default useDeleteLoan