import usePut from "../usePut";

const useDownloadTransactionReport = () => {
  const { handleAuth: downloadReq } = usePut({
    url: "petty_cash_transaction_report",
    onSuccess: (res) => {
      console.log("res", res);
      const href = URL.createObjectURL(new Blob([res.data]));
      let a = document.createElement("a");
      a.href = href;
      a.download = "petty_cash_transaction_report.pdf";
      a.click();
    },
  });

  const downloadReport = (pettyCashId) => {
    downloadReq.mutate({
      petty_cash_id: pettyCashId,
    });
  };

  return { downloadReq, downloadReport };
};

export default useDownloadTransactionReport;
