import PettyCashForm from "../../components/sections/PettyCash/PettyCashForm";
import useGetSingleData from "../../hooks/PettyCash/useGetSingleData";
import TransactionTable from "./TransactionTable";
import { useParams, useSearchParams } from "react-router-dom";

const PettyCashEdit = () => {
  const { pettyCashId } = useParams();
  const { responseData } = useGetSingleData({ id: pettyCashId });
  return (
    <>
      <div className="px-4 py-4">
        <PettyCashForm formData={responseData?.data?.data} mode={"edit"} />
      </div>
      <div>
        <TransactionTable pettyCashId={pettyCashId} />
      </div>
    </>
  );
};

export default PettyCashEdit;
