import { Drawer } from "@mui/material"
import { Button } from "../../../components/ui"
import { ArrowLeftIcon } from "@heroicons/react/solid"

const DocumentsDrawer = ({
    data,
    open,
    onClose,
    onRemoveDoc
}) => {
    return (
        <Drawer
            anchor={'right'}
            open={open}
            onClose={onClose}
        >
            <div className='w-[600px] bg-white'>
                <div className=" flex items-center border-b border-gray-400 py-3 space-x-3 px-4">
                    <Button size={'icon'} variant={'ghost'} onClick={onClose}>
                        <ArrowLeftIcon className='w-6 h-6' />
                    </Button>
                    <span className=" font-semibold text-xl">Documents</span>
                </div>
                <div className="p-4">
                    {data?.length > 0 
                    ? <div className=" grid grid-cols-3 gap-4">
                    {data?.map((item, idx) => {
                        const { image_url, image_id } = item || {}
                        return <div key={idx} className=" space-y-2">
                            <a href={image_url} target="blank" className=" bg-gray-100 rounded-md w-full h-24 cursor-pointer flex items-center justify-center">
                                <span className=" font-semibold tracking-wider text-xs">View Document</span>
                            </a>
                            <Button onClick={() => onRemoveDoc(image_id)} className={'w-full'} variant={'destructive'}>
                                Remove
                            </Button>
                        </div>
                    })}
                </div> 
                    : <p className=" py-10 text-xl font-semibold text-center">No Documents Found</p>}
                </div>
            </div>
        </Drawer>
    )
}

export default DocumentsDrawer