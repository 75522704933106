import axios from '../api/axios'
import { useMutation,useQueryClient } from "@tanstack/react-query";
import Cookies from "universal-cookie";
import { TOKEN_NAME } from "../config";
const cookies = new Cookies();


const usePut = (formPostObject) => {
  const queryClient = useQueryClient()
  const { url, onSuccess, onError, onSettled,refreshUrl } = formPostObject
  const handleAuth = useMutation({
    mutationFn: (data) => handleFormSubmit(data),
    onSuccess: (data) => {
      onSuccess?.(data)
      {refreshUrl && queryClient.invalidateQueries(refreshUrl)}
    },
    onError: (err) => {
      onError?.(err)
    },
    onSettled: () => {
      onSettled?.()
    }
  })
  const handleFormSubmit = async (getFormData) => {
    let token = cookies.get(TOKEN_NAME);
    let fd = new FormData()
    for (var key in getFormData) {
      if(key === 'document'){
        fd.append(key, getFormData[key]);
      }else if(key === 'month_year'){
        fd.append(key, getFormData[key]);
      }else{
        if (Array.isArray(getFormData[key]) || typeof getFormData[key] === 'object') {
          fd.append(key, JSON.stringify(getFormData[key]));
        } else {
          fd.append(key, getFormData[key]);
        }
      }
    }

    const response = await axios({
      method: 'POST',
      url: `/${url}`,
      data: fd,
      headers: { Authorization: token ? `Bearer ${token}` : '', }
    })
    return response
  }
  return { handleAuth }
}

export default usePut