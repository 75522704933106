import { useState } from "react"
import { useQuery } from "@tanstack/react-query";
import Cookies from "universal-cookie";
import { TOKEN_NAME, config } from "../../config";
import axios from "../../api/axios";
const cookies = new Cookies();

const useReadWithGet = ({
    url, initialData,onSuccess,onError, method
}) => {
    const [paramsObject, setGetListParams] = useState(initialData)
    const list = useQuery([url, paramsObject], () => getUsers(paramsObject), {
        refetchOnWindowFocus: false,
        onSuccess(data) {
              onSuccess?.(data)
        },
        onError(err) {
              onError?.(err)
        },
        onSettled() {
            //   onSettled?.()
        }
    })
    const getUsers = async (paramsObject) => {
        let token = cookies.get(TOKEN_NAME); 
        let fd = new FormData()
        for (var key in paramsObject) {
            fd.append(key, paramsObject[key]);
        }
        const response = await axios({
            method: method || "POST",
            data: fd,
            url: `/${url}`,
            headers: { Authorization: token ? `Bearer ${token}` : '', }
        })
        return response.data
    }

    return { list, paramsObject, setGetListParams }
}

export default useReadWithGet