import axios from 'axios';
import {config} from '../config'

// const BASE_URL = 'https://api-rentmanagement.pandayschool.in/';
// const BASE_URL = 'http://192.168.1.35/';
// const BASE_URL = 'http://pandeyapi.local.com/';

export default axios.create({
    baseURL: config.apiurl,
});

export const axiosPrivate = axios.create({
    baseURL: config.apiurl,
    headers: { 'Content-Type': 'application/json' },
    // withCredentials: true
});