import Layout from "../../components/layout/Layout";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import {
  getAttendanceList,
  getsingleDayWiseUsersAttendanceData,
  saveUpdateAttendance,
} from "../../api/attendance";
import { EyeIcon, PencilAltIcon, XIcon } from "@heroicons/react/solid";
import { Modal } from "@mui/material";
import spinner from "../../assets/images/spinner.gif";
import ViewAttendanceDetail from "./ViewAttendanceDetail";
import Moment from "react-moment";
import { useQuery } from "@tanstack/react-query";
import Pagination from "../../components/pagination";
import useGet from "../../hooks/read/useGet";
import toast from "react-hot-toast";

const processing_status = {
  1: {
    color: "bg-green-200 text-green-600",
    value: "Attendance Processed",
  },
  2: {
    color: "bg-yellow-200 text-yellow-600",
    value: "Attendance Processing",
  },
  3: {
    color: "bg-red-200 text-red-600",
    value: "Attendance Not Processed",
  },
};

const attendanceShift = {
  present: {
    color: "bg-green-200 text-green-600",
    value: "Present",
  },
  absent: {
    color: "bg-red-200 text-red-600",
    value: "Absent",
  },
  half_day: {
    color: "bg-lime-200 text-black",
    value: "Half Day",
  },
  weekend: {
    color: "bg-blue-200 text-blue-600",
    value: "Leave",
  },
  on_approved_leave: {
    color: "bg-blue-200 text-blue-600",
    value: "Leave",
  },
  under_process: {
    color: "bg-orange-200 text-orange-600",
    value: "Under Process",
  },
};
const IS_REQUIRED = true;

function DateWiseAttendance({ onDateChange }) {
    const [pageNo, setPageNo] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { id } = useParams();
  const location = useLocation(); // Use useLocation to get the current location

  // Extract the employee_name from the query parameters
  const queryParams = new URLSearchParams(location.search);
  const employeeName = queryParams.get("employee_name");
  //   console.log(employeeName, "employee_name");

  let initialData = {
    month_year: new Date(),
    page_number: 0,
    limit: 10,
  };
  let initialAttendanceData = {
    date: new Date(),
    in_time: new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }),
    out_time: new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }),

    id: "",
    shift: "",
    status: "",
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };
  const [paramsObject, setGetListParams] = useState(initialData);
  const [filterData, setFilterData] = useState(initialData);
  const [isFetching, setIsFetching] = useState(false);
  const [isDataFound, setDataFound] = useState(true);
  const [attendanceList, setAttendanceList] = useState([]);
  const [addAttendanceModal, setAddAttendanceModal] = useState(false);
  const [attendanceFormData, setAttendanceFormData] = useState(
    initialAttendanceData
  );
  const [isSaving, setIsSaving] = useState(false);
  const [attendanceEditModal, setEditAttendanceModal] = useState(false);
  const [singleAttendanceData, setSingleAttendanceData] = useState({});
  const [attendanceId, setAttendanceData] = useState("");
  const [isDateCalculated, setIsDateCalculated] = useState(false);

  const handleDate = (value, name) => {
    let data = {
      ...filterData,
      [name]: value,
    };
    setFilterData(data);
    // Pass the selected date to the parent component
    if (onDateChange) {
      onDateChange(data.month_year);
    }
  };

  const handleCalculateSalary = () => {
    setDataFound(true);
    setIsFetching(true);
    getsingleDayWiseUsersAttendanceData(filterData)
      .then((res) => {
        if (res.status === 200) {
          // onFetchAttendanceData(res.data);
          setAttendanceList(res.data);
          if (res.data.length <= 0) {
            setDataFound(false);
          }
          setIsFetching(false);
          setIsDateCalculated(true);
        }
      })
      .catch((err) => {
        alert("Something went wrong!");
        setIsFetching(false);
      });
  };

  const handleHolidayFormInputs = (value) => {
    let data;
    if (value.isDateTime) {
      data = {
        ...attendanceFormData,
        [value.name]: value.value,
      };
    } else {
      let val = value.target.value;
      data = {
        ...attendanceFormData,
        [value.target.name]: val,
      };
    }
    setAttendanceFormData(data);
  };

  const handleCancelForm = () => {
    setAddAttendanceModal(false);
    setEditAttendanceModal(false);
    setAttendanceFormData(initialAttendanceData);
    setAttendanceData("");
  };
  const handleAddAttendance = () => {
    setAddAttendanceModal(true);
  };
  const handleSaveAttendance = (e, action) => {
    setIsSaving(true);
    e.preventDefault();
    saveUpdateAttendance(attendanceFormData, action, attendanceId)
      .then((res) => {
        // console.log(res)
        if (res.status === 200) {
          setIsSaving(false);
          handleCancelForm();
          handleCalculateSalary();
        }
      })
      .catch((err) => {
        alert("Something went wrong please try again later!");
        setIsSaving(false);
      });
  };

  const handleGetSingleData = (id) => {
    setAttendanceData(id);
    setEditAttendanceModal(true);

    // Check if attendanceList is an array before calling find
    if (Array.isArray(attendanceList?.data)) {
      const data = attendanceList?.data.find((data) => data.id === id);

      // Check if data is found
      if (data) {
        setSingleAttendanceData(data);
        setAttendanceFormData(data);
      } else {
        console.error("Attendance data not found for the given id:", id);
      }
    } else {
      console.error("attendanceList is not an array:", attendanceList);
    }

    // console.log(attendanceList);
  };

  function tConvert(time) {
    if (!time || time === undefined) {
      return (time = "---");
    }
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
      time.splice(3, 1);
    }
    return time.join(""); // return adjusted time or original string
  }

  const handleMarkLate = (e) => {
    let checked = e.target.checked;
    setAttendanceFormData((prev) => {
      return {
        ...prev,
        late: checked,
      };
    });
  };
  const [isViewAttendanceDrawerShow, setIsViewAttendanceDrawerShow] =
    useState(false);
  const handleOpenAttendanceDrawer = (id) => {
    setIsViewAttendanceDrawerShow(true);
    setAttendanceData(id);
  };
  const fetchAttendanceData = async (updatedParams) => {
    setIsFetching(true);
    try {
      const response = await getsingleDayWiseUsersAttendanceData(updatedParams);
      //   console.log("API Response:", response); // Log the full API response

      if (response.status === 200) {
        const data = Array.isArray(response.data.data) ? response.data : [];
        // console.log("Fetched Data:", response, data);
        // console.log(data, "updated");
        setAttendanceList(data);

        if (data.length <= 0) {
          setDataFound(false);
        } else {
          setDataFound(true);
        }
      } else {
        setDataFound(false);
      }
    } catch (error) {
      alert("Something went wrong! Please try again.");
      console.error("Error:", error); // Log the error for debugging
      setDataFound(false);
    } finally {
      setIsFetching(false);
    }
  };

  const handlePageClick = (val) => {
    // Update the paramsObject with the selected page number
    const updatedParams = {
      ...filterData,
      page_number: val.selected,
    };

    // Update state with the new parameters

    setSearchParams(updatedParams);
    setPageNo(val.selected);
    // Call the API with the updated parameters
    fetchAttendanceData(updatedParams);
    setGetListParams(updatedParams);
  };

//   console.log(attendanceList?.data, "data");

  return (
    <>
      <div className="px-4 mb-20">
        <form>
          <div className="flex space-x-4 items-end">
            <div className="flex space-x-4 items-end flex-1">
              <div className=" basis-[25%]">
                <label className=" roboto p-2 label">Select Date</label>
                <DatePicker
                  required
                  name="month_year"
                  autoComplete="false"
                  className="input"
                  dateFormat="dd/MM/yyyy"
                  // showMonthYearPicker
                  selected={filterData?.month_year}
                  onChange={(value) => handleDate(value, "month_year")}
                />
              </div>
              <div>
                <button
                  type="button"
                  onClick={handleCalculateSalary}
                  className=" bg-[color:var(--color1)] text-white rounded px-4 py-2 mt-12"
                >
                  Fetch Attendance
                </button>
              </div>
            </div>
            {isDateCalculated && (
              <div>
                <button
                  type="button"
                  onClick={handleAddAttendance}
                  className=" bg-[color:var(--color1)] text-white rounded px-4 py-2 mt-12"
                >
                  Add Attendance
                </button>
              </div>
            )}
          </div>
        </form>
        {attendanceList.isLoading ? (
          <div className=" w-12 h-12 mx-auto mt-12">
            <img src={spinner} alt="spinner" className="w-full h-full" />
          </div>
        ) : (
          <>
            <div className="max-w-full overflow-x-auto mt-8">
              <table className="table-auto w-full">
                <thead>
                  <tr className=" bg-[color:var(--color1)] text-center">
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">
                      Sr.No
                    </th>
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Employee Name
                    </th>
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Day
                    </th>
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      In-Time
                    </th>
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Out-Time
                    </th>
                    {/* <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Shift
                    </th> */}
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Attendance
                    </th>
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {attendanceList?.data?.map((data, idx) => {
                    const {
                      attendance_date,
                      employee_name,
                      attendance_month,
                      attendance_year,
                      in_time,
                      out_time,
                      shift,
                      id,
                      status,
                      date,
                      present_definition,
                    } = data;
                    return (
                      <tr key={idx}>
                        <td className="text-center text-dark font-medium text-sm py-2 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                        {pageNo * initialData.limit + idx + 1}
                        </td>
                        <td className="text-center text-dark font-medium text-sm py-2 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                          {employee_name}
                        </td>
                        <td className="text-center text-dark font-medium text-sm py-2 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                          <Moment date={date} format="dddd" />
                        </td>
                        <td className="text-center text-dark font-medium text-sm py-2 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                          {tConvert(in_time)}
                        </td>
                        <td className="text-center text-dark font-medium text-sm py-2 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                          {tConvert(out_time)}
                        </td>
                        {/* <td className="text-center text-dark font-medium text-sm py-2 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                            {shift}
                          </td> */}
                        <td className="text-center text-dark font-medium text-sm py-2 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                          {/* {attendanceShift[present_definition]?.value} */}
                          <span
                            className={`${attendanceShift[present_definition]?.color} px-4 py-1 rounded-full text-sm`}
                          >
                            {attendanceShift[present_definition]?.value}
                          </span>
                          {/* {status === 'P' ? <span className="text-sm text-orange-700 bg-orange-200 px-4 py-1 rounded-full">Present</span> : <span className="text-sm text-red-700 bg-red-200 px-4 py-1 rounded-full">Absent</span>} */}
                        </td>
                        <td className="text-center text-dark font-medium text-sm py-2 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                          <div className="flex items-center justify-center space-x-4">
                            <div
                              className="cursor-pointer w-6"
                              onClick={() => handleOpenAttendanceDrawer(id)}
                            >
                              <EyeIcon className=" text-gray-600 w-full  hover:text-blue-600" />
                            </div>
                            <div
                              className="cursor-pointer w-6"
                              onClick={() => handleGetSingleData(id)}
                            >
                              <PencilAltIcon className=" text-gray-600 w-full  hover:text-blue-600" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="mt-5">
              {attendanceList?.length <= 0 ? (
                ""
              ) : (
                <Pagination
                  currentPage={attendanceList?.page_number}
                  lengthofItems={+attendanceList?.total_count}
                  limit={attendanceList?.per_page}
                  onPageChange={handlePageClick}
                />
              )}
            </div>
          </>
        )}
      </div>
      <Modal
        open={addAttendanceModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <div className="w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]">
          <div className="document-wrapper px-4 divide-y">
            <div className="document-head py-4">
              <div className="flex items-center justify-between">
                <h3 className=" text-gray-700 text-3xl font-semibold">
                  Add Attendance
                </h3>
                <div
                  className=" w-6 h-6 cursor-pointer"
                  onClick={handleCancelForm}
                >
                  <XIcon className=" text-black w-full h-full" />
                </div>
              </div>
            </div>
            <div className="document-body max-h-96 overflow-auto">
              <form
                method="post "
                className="divide-y"
                onSubmit={(e) => handleSaveAttendance(e, "Save")}
              >
                <div className="grid grid-cols-2 gap-4 py-4">
                  <div className="">
                    <label htmlFor="holiday_name" className="label">
                      Date:<span className="mandatory">*</span>
                    </label>
                    <DatePicker
                      selected={attendanceFormData.date}
                      name="date"
                      onChange={(value) =>
                        handleHolidayFormInputs({
                          value,
                          name: "date",
                          isDateTime: true,
                        })
                      }
                      className="input"
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                  <div className="">
                    <label htmlFor="in_time" className="label">
                      In Time:<span className="mandatory">*</span>
                    </label>
                    <input
                      type="time"
                      name="in_time"
                      value={attendanceFormData.in_time}
                      className="input"
                      onChange={(e) => handleHolidayFormInputs(e)}
                    />
                  </div>
                  <div className="">
                    <label htmlFor="out_time" className="label">
                      Out Time:<span className="mandatory">*</span>
                    </label>
                    <input
                      type="time"
                      name="out_time"
                      value={attendanceFormData.out_time}
                      className="input"
                      onChange={(e) => handleHolidayFormInputs(e)}
                    />
                  </div>
                  {/* <div className="">
                                        <label htmlFor="shift" className='label'>Shift:<span className='mandatory'>*</span></label>
                                        <input type="text" name='shift' className='input' required={IS_REQUIRED} value={attendanceFormData.shift} onChange={(e) => handleHolidayFormInputs(e)} placeholder='Shift' />
                                    </div> */}
                  <div className="">
                    <label htmlFor="status" className="label">
                      Status:<span className="mandatory">*</span>
                    </label>
                    <select
                      className="input"
                      name="status"
                      required={IS_REQUIRED}
                      value={attendanceFormData.status}
                      onChange={(e) => handleHolidayFormInputs(e)}
                    >
                      <option value="">--Select Attendance--</option>
                      <option value="P">Present</option>
                      <option value="A">Absent</option>
                    </select>
                  </div>
                </div>
                <div className="document-foot space-x-4 py-4">
                  {isSaving ? (
                    <div className=" w-8 h-8 mx-auto">
                      <img
                        src={spinner}
                        alt="spinner"
                        className="w-full h-full"
                      />
                    </div>
                  ) : (
                    <div className="text-center space-x-4">
                      <button className=" py-2 px-4 rounded font-semibold bg-[color:var(--color1)] text-white">
                        Save
                      </button>
                      <button
                        type="button"
                        className=" py-2 px-4 rounded font-semibold border border-[color:var(--color1)]"
                        onClick={handleCancelForm}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={attendanceEditModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <div className="w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[1%]">
          <div className="document-wrapper px-4 divide-y">
            <div className="document-head py-4">
              <div className="flex items-center justify-between">
                <h3 className=" text-gray-700 text-3xl font-semibold">
                  Edit Attendance
                  <span
                    className={` px-4 py-1 font-semibold rounded-md ${
                      processing_status[attendanceFormData?.processing_status]
                        ?.color
                    }`}
                  >
                    {
                      processing_status[attendanceFormData?.processing_status]
                        ?.value
                    }
                  </span>
                </h3>
                <div
                  className=" w-6 h-6 cursor-pointer"
                  onClick={handleCancelForm}
                >
                  <XIcon className=" text-black w-full h-full" />
                </div>
              </div>
            </div>
            <div className="document-body max-h-[34rem] overflow-auto">
              <form
                method="post "
                className="divide-y"
                onSubmit={(e) => handleSaveAttendance(e, "Update")}
              >
                <div className="grid grid-cols-2 gap-4 py-4">
                  <div className="">
                    <label htmlFor="holiday_name" className="label">
                      Date:<span className="mandatory"></span>
                    </label>
                    <input
                      type="date"
                      name="date"
                      readOnly
                      disabled
                      value={attendanceFormData.date}
                      className="input"
                      onChange={(e) => handleHolidayFormInputs(e)}
                    />
                  </div>
                  <div className="">
                    <label htmlFor="in_time" className="label">
                      In Time:<span className="mandatory">*</span>
                    </label>
                    <input
                      type="time"
                      name="in_time"
                      value={attendanceFormData.in_time}
                      className="input"
                      onChange={(e) => handleHolidayFormInputs(e)}
                    />
                  </div>
                  <div className="">
                    <label htmlFor="out_time" className="label">
                      Out Time:<span className="mandatory">*</span>
                    </label>
                    <input
                      type="time"
                      name="out_time"
                      value={attendanceFormData.out_time}
                      className="input"
                      onChange={(e) => handleHolidayFormInputs(e)}
                    />
                  </div>
                  {/* <div className="">
                                        <label htmlFor="shift" className='label'>Shift:<span className='mandatory'>*</span></label>
                                        <input type="text" name='shift' className='input' required={IS_REQUIRED} value={attendanceFormData.shift} onChange={(e) => handleHolidayFormInputs(e)} placeholder='Shift' />
                                    </div> */}
                  <div className="">
                    <label htmlFor="status" className="label">
                      Status:<span className="mandatory">*</span>
                    </label>
                    <select
                      className="input"
                      name="status"
                      required={IS_REQUIRED}
                      value={attendanceFormData.status}
                      onChange={(e) => handleHolidayFormInputs(e)}
                    >
                      <option value="">--Select Attendance--</option>
                      <option value="P">Present</option>
                      <option value="A">Absent</option>
                    </select>
                  </div>
                  <div className="">
                    <label htmlFor="status" className="label">
                      Present Definition:
                    </label>
                    <p className="input">
                      {attendanceFormData?.present_definition || "---"}
                    </p>
                  </div>
                  <div className="">
                    <div>
                      <label htmlFor="out_time" className="label">
                        Late Duration
                      </label>
                      <p className="input">
                        {attendanceFormData?.late_duration || "---"}
                      </p>
                    </div>
                    <div className="mt-2">
                      <label className=" space-x-2">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          checked={attendanceFormData?.late}
                          onChange={handleMarkLate}
                        />
                        <span>Mark Late</span>
                      </label>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="out_time" className="label">
                      Total Working Hours
                    </label>
                    {
                      <p className="input">
                        {attendanceFormData?.total_worked_hours || "---"}
                      </p>
                    }
                  </div>
                </div>
                <div className="document-foot space-x-4 py-4">
                  {isSaving ? (
                    <div className=" w-8 h-8 mx-auto">
                      <img
                        src={spinner}
                        alt="spinner"
                        className="w-full h-full"
                      />
                    </div>
                  ) : (
                    <div className="text-center space-x-4">
                      <button className=" py-2 px-4 rounded font-semibold bg-[color:var(--color1)] text-white">
                        Update
                      </button>
                      <button
                        type="button"
                        className=" py-2 px-4 rounded font-semibold border border-[color:var(--color1)]"
                        onClick={handleCancelForm}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <ViewAttendanceDetail
        id={attendanceId}
        onClose={() => setIsViewAttendanceDrawerShow(false)}
        open={isViewAttendanceDrawerShow}
      />
    </>
  );
}

export default DateWiseAttendance;
