import React, { useState } from 'react'
import { useSearchParams } from "react-router-dom";
import { XIcon } from '@heroicons/react/solid';
import Modal from '@mui/material/Modal';
import EmployeeTab from './EmployeeTab';
import useGet from '../../hooks/read/useGet';
import Pagination from '../../components/pagination';
import usePut from '../../hooks/usePut';
import toast, { Toaster } from 'react-hot-toast';
import InlineLoader from '../../components/loader/InlineLoader';
import SingleResignEmployeeDetail from './SingleResignEmployeeDetail';
import PrimaryLoader from '../../components/common/PrimaryLoader';

const ResignedEmployeeDetails = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialEmployeeState = {
        limit: 50,
        search_query: searchParams.get('search_query') || '',
        page_number: searchParams.get('page_number') || 0,
        screen_name: 'resignation'
    }
    const [isOpenDocumentModal, setIsOpenDocumentModal] = useState(false)
    const { list: resignedUserList, paramsObject, setGetListParams } = useGet({
        url: 'employeelist',
        initialData: initialEmployeeState
    })
    const { handleAuth: revokeEmployee } = usePut({
        url: 'revokeUserResignation',
        refreshUrl: 'employeelist',
        onSuccess: () => {
            toast.success('Employee Revoked Successfully')
            setIsOpenDocumentModal(false)
        },
        onError: () => {
            toast.error('There Was an error while submitting your request')
        }
    })
    const [userId, setUserId] = useState('')

    const handleFetchUserDocuments = (id) => {
        setUserId(id)
        setIsOpenDocumentModal(true)
    }

    const handleSearch = (e) => {
        let value = e.target.value
        let data = {
            ...paramsObject,
            search_query: value,
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            page_number: val.selected
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    const handleRevokeEmployee = () => {
        let data = {
            user_id: userId
        }
        revokeEmployee.mutate(data)
    }
    const [openUserDetailDrawer, setOpenUserDetailDrawer] = useState(false)
    const handleOpenResignEmployeeDetail = (id) => {
        setUserId(id)
        setOpenUserDetailDrawer(true)
    }
    const handleOpenRevokeUserModal = (e, id) => {
        e.stopPropagation()
        handleFetchUserDocuments(id)
    }
    return (
        <>
            <Toaster />
                <EmployeeTab />
                <div className=' px-4 pt-4 w-[40%]'>
                    <input type="search" placeholder='Search By employee name...' name='search_query' value={paramsObject.search_query} className='input' onChange={handleSearch} />
                </div>
                {resignedUserList.isLoading ? <div className=' flex items-center justify-center mt-10'> <PrimaryLoader /></div> 
                : <>
                {resignedUserList.data?.data?.length <= 0 ? <div className='text-center'><p className=' text-gray-700 text-3xl mt-4'>No Employee Found</p></div> : <div class="max-w-full overflow-x-auto px-4 mt-8">
                    <table class="table-auto w-full">
                        <thead>
                            <tr class=" bg-[color:var(--color1)] text-center">
                                <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Name</th>
                                <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Username</th>
                                <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Email</th>
                                <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">User Type</th>
                                <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                resignedUserList.data?.data?.map((data, index) => {
                                    const { employee_name, username, email, id, type_of_employee } = data
                                    return <tr key={index} className=' cursor-pointer' onClick={() => handleOpenResignEmployeeDetail(id, employee_name)}>
                                        <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{employee_name}</td>
                                        <td class=" text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{username}</td>
                                        <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{email}</td>
                                        <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{type_of_employee}</td>
                                        <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                                            <div className='flex items-center justify-center w-full'>
                                                <button type='button' onClick={(e) => handleOpenRevokeUserModal(e, id)} className='bg-[color:var(--color1)] text-white rounded px-4 py-2 flex items-center space-x-2 justify-center'>
                                                    <span>Revoke</span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                    <div className='px-5 mt-5'>
                        <Pagination
                            currentPage={+paramsObject.page_number}
                            lengthofItems={resignedUserList.data?.total_count}
                            limit={paramsObject.limit}
                            onPageChange={handlePageClick}
                        />
                    </div>
                </div>}
                </>}
            <Modal open={isOpenDocumentModal} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
                <div className='w-[30%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%] rounded'>
                    <div className='document-wrapper px-4 divide-y'>
                        <div className="document-head py-4">
                            <div className='flex items-center justify-between'>
                                <h3 className=' text-gray-700 text-3xl font-semibold'>Revoke Employee</h3>
                                <div className=' w-6 h-6 cursor-pointer' onClick={() => setIsOpenDocumentModal(false)}>
                                    <XIcon className=' text-black w-full h-full' />
                                </div>
                            </div>
                        </div>
                        <div className="document-body py-4 max-h-96 overflow-auto">
                            <p>Are You sure you want to revoke this employee?</p>
                        </div>
                        <div className="document-foot space-x-4 py-4">
                            <button disabled={revokeEmployee.isLoading} className=' py-2 px-4 rounded font-semibold bg-[color:var(--color1)] text-white' onClick={handleRevokeEmployee}>
                                <div className=' flex items-center justify-center space-x-3'>
                                    <span>Revoke</span>
                                    {revokeEmployee.isLoading && <InlineLoader />}

                                </div>
                            </button>
                            <button className=' py-2 px-4 rounded font-semibold border border-[color:var(--color1)]' onClick={() => setIsOpenDocumentModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            </Modal>
            {openUserDetailDrawer && <SingleResignEmployeeDetail userId={userId} open={openUserDetailDrawer} handleCloseDialog={() => setOpenUserDetailDrawer(false)}/>}
        </>
    )
}

export default ResignedEmployeeDetails