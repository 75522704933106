import { Drawer } from '@mui/material'
import React, { useEffect, useState } from 'react'
import usePut from '../../hooks/usePut'
import { ArrowLeftIcon } from '@heroicons/react/solid'
import InlineLoader from '../../components/loader/InlineLoader'

const EditHandBook = ({
    open,
    handleCloseDialog,
    data
}) => {
    const documentRef = React.useRef(null)
    const [previewData,setPreviewData] = useState()
    const [handBookState, setHandBookState] = useState({
        document_name: '',
        document: ''
    })
    const { handleAuth } = usePut({
        url: 'add_company_documents',
        refreshUrl:'company_documents_list',
        onSuccess: () => {
            handleCloseDialog()
        }
    })
    const handleSubmitData = (e) => {
        e.preventDefault()
        handleAuth.mutate(handBookState)
    }
    const handleChangeForm = (e) => {
        let name = e.target.name;
        let value = e.target.value
        setHandBookState(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    const handleFileUpload = (e) => {
        let file = e.target.files[0]
        setHandBookState(prev => {
            return {
                ...prev,
                document: file
            }
        })
        setPreviewData('')
    }
    useEffect(() => {
        if(data){
            console.log(data);
            setHandBookState(data)
            setPreviewData(data.media_url)
        }
    },[data])
    return (
        <Drawer anchor={'right'} open={open} onClose={handleCloseDialog}>
            <div className='h-full bg-white' style={{ width: 'calc(100vw - 850px)' }}>
                <form className=' space-y-8' onSubmit={handleSubmitData}>
                    <div className='flex items-center justify-between px-4 py-5 border-b border-gray-200 sticky z-40 top-0 bg-white'>
                        <div className='flex items-center space-x-3'>
                            <ArrowLeftIcon className=' text-gray-700 w-6 h-6 cursor-pointer' onClick={handleCloseDialog} />
                            <p className='text-gray-700 text-2xl font-semibold'>Edit Document</p>
                        </div>
                        <button disabled={handleAuth.isLoading} className=' bg-color1 px-5 py-2 text-lg text-white rounded-md'>
                            <div className='flex items-center space-x-3'>
                                <span>Save document</span>
                                {handleAuth.isLoading && <InlineLoader/>}
                            </div>
                        </button>
                    </div>
                    <div className='px-4'>
                        <div>
                            <label className='label'>Document Name </label>
                            <input type="text" name='document_name' className='input' onChange={handleChangeForm} value={handBookState.document_name} />
                        </div>
                        <div>
                            <button onClick={() => documentRef.current.click()} type='button' className=' px-5 py-1 rounded font-semibold text-sm uppercase bg-blue-600 text-white mt-4' >Add Document</button>
                            <input type="file" className='hidden' accept=".pdf" ref={documentRef} onChange={handleFileUpload} />
                        </div>
                        {handBookState.document && 
                        <div>
                            <a href={URL.createObjectURL(handBookState.document)} target="_blank" rel="noopener noreferrer" className=' text-blue-600 font-semibold mt-4 block'>
                                Preview {handBookState.document.name}
                            </a>
                        </div>}
                        {previewData && 
                        <div>
                            <a href={previewData} target="_blank" rel="noopener noreferrer" className=' text-blue-600 font-semibold mt-4 block'>
                                Preview Document
                            </a>
                        </div>}
                    </div>
                </form>
            </div>
        </Drawer>
    )
}

export default EditHandBook