import React, { forwardRef } from 'react'
import Input from '../../components/form/Input'

const LeavesCalculation = forwardRef(({ control, employeeType, onChangeText, type }, ref) => {
    return (
        <div className=' space-y-5'>
            <>
                <div className="">
                    <label htmlFor="pl_leave" className='label'>Other Leave:</label>
                    <div className='grid grid-cols-2 gap-4'>
                        <div className='mt-4'>
                            <span className='mb-2 text-sm font-semibold inline-block'>Annual Leave</span>
                            <Input name={'leaves.annual'} placeholder={'Annual Leave'} onChangeText={(value) => onChangeText(value, 'leaves.available')} control={control}
                                rules={{
                                    required: 'Annual Leave required',
                                }}
                            />
                        </div>
                        <div className='mt-4'>
                            <span className='mb-2 text-sm font-semibold inline-block'>Available Leave</span>
                            <Input name={'leaves.available'} placeholder={'Available Leave'} control={control}
                                rules={{
                                    required: 'Available Leave required',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
})

export default LeavesCalculation