import { cva } from "class-variance-authority"
import { cn } from "../../utility/utils"

const buttonVariants = cva(
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm outline-none font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
    {
        variants: {
            variant: {
                default:
                    "bg-primary text-primary-foreground shadow hover:bg-primary/50",
                destructive:
                    "bg-red-600 text-white shadow-sm hover:bg-red-600/50",
                outline:
                    "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
                secondary:
                    "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
                warning:
                    "bg-yellow-300 text-secondary-foreground shadow-sm hover:bg-yellow-600/50",
                ghost: "hover:bg-gray-100 hover:text-accent-foreground",
                link: "text-primary underline-offset-4 hover:underline",
            },
            size: {
                default: "px-4 py-2",
                sm: "rounded-md px-3 text-xs",
                lg: "rounded-md px-8",
                icon: "h-9 w-9",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    }
)
const Button = ({ children, className, variant, size, asChild = false, ...props }) => {

    return (
        <>
            <button
                {...props}
                className={cn(buttonVariants({ variant, size, className }))}
            >
                {children}
            </button>
        </>
    )
}

export default Button