import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import useGet from '../../hooks/read/useGet'
import PrimaryLoader from '../../components/common/PrimaryLoader'
import LikertView from '../../components/likert'
import Moment from 'react-moment'
import Spinner from '../../components/loader/Spinner'

const ShiftDetails = () => {
    const [isShowWeek, setShowWeek] = useState(false)
    const { list: shiftDetail } = useGet({
        url: 'user_shift_details',
        initialData: {},
        onSuccess: () => {
            setTimeout(() => {
                setShowWeek(true)
            }, 2000)
        }
    })
    return (
        <>
            {shiftDetail.isLoading && 
            <div className=' bg-white/90 fixed top-0 left-0 w-full h-full z-30 flex items-center justify-center'> <Spinner />
            </div>}
            <div className='px-5'>
                <div>
                    <p className=' text-2xl font-semibold py-2'>Shift Details</p>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-3 gap-y-5 gap-x-5 py-5'>
                    <div>
                        <div className=''>
                            <label className='label'>Current Shift: </label>
                            <span className='block font-semibold text-base bg-red-100 text-red-600 py-2 rounded-md text-center'>
                                {shiftDetail.data?.data?.shiftName}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <label className='label'>Effected From </label>
                            <p className='input'>
                                <Moment unix format='LL'>
                                    {shiftDetail.data?.data?.from_date}
                                </Moment>
                            </p>
                        </div>
                    </div>
                </div>
                {shiftDetail.data?.data?.shift_type !== 'lenient' &&
                    <div className='py-5'>
                        <p className='text-gray-700 text-3xl font-semibold mb-4'>Weekend Definition</p>
                        {
                            isShowWeek &&
                            <LikertView
                                disabled={true}
                                values={shiftDetail.data?.data?.weekendDefinition}
                            />}
                    </div>}
            </div>
        </>
    )
}

export default ShiftDetails