import toast from "react-hot-toast";

export const throwError = (err) => {
  let status;
  let message;
  if (err) {
    const { response } = err;
    status = response.status;
    message = response.data.message;
    if (status >= 400 && status <= 499) {
      // toast.error();
    } else if (status >= 500 && status <= 599) {
      toast.error("Internal Server Error");
    }
  }
};
