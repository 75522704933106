import { Modal } from '@mui/material'
import React from 'react'
import usePut from '../../hooks/usePut'
import InlineLoader from '../../components/loader/InlineLoader'

const DeleteHandBook = ({
    open,
    onClose,
    id
}) => {
    const { handleAuth } = usePut({
        url: 'delete_company_documents',
        refreshUrl: 'company_documents_list',
        onSuccess: () => {
            onClose()
        }
    })
    const handleDeleteDocument = () => {
        handleAuth.mutate({ id })
    }
    return (
        <Modal open={open} onClose={onClose} className=''>
            <div className=' absolute top-[1%] left-1/2 -translate-x-1/2 bg-white rounded px-4 py-4'>
                <p className=' roboto text-center font-bold text-2xl text-gray-700'>Are you sure you want to delete this document?</p>
                <div className=" mt-12 space-x-3 text-center ">
                    <button className='px-4 py-2 rounded-md font-semibold bg-red-200 text-red-700 border-red-800' onClick={handleDeleteDocument}>
                        <div className='flex items-center space-x-3'>
                            <span>Yes</span>
                            {handleAuth.isLoading && <InlineLoader />}
                        </div>
                    </button>
                    <button className='px-4 py-2 rounded-md font-semibold  border' onClick={onClose}>Cancel</button>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteHandBook