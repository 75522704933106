import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Cookies from "universal-cookie";
import NotAllowed from '../pages/errors/NotAllowed'
import { ROLE, TOKEN_NAME } from '../config';
const cookies = new Cookies();

function ProtectedRoutes({ component: Component, ...restOfProps }) {
    let token = cookies.get(TOKEN_NAME);
    let role = cookies.get(ROLE);
    const { children, accessTo } = restOfProps
    if (!token) {
        return <Navigate to="/login" replace />;
    } else {
        return <Outlet />
        // if (accessTo === 'all') {
        //     return children;
        // } else if (accessTo.includes(role)) {
        //     return <Outlet />;
        // } else {
        //     return <NotAllowed />;
        // }
    }
    // return children;
}

export default ProtectedRoutes