import usePut from '../usePut'

const useGetOpeningBalance = ({
    onSuccess
}={}) => {
    const { handleAuth: mutateOpeningBalance } = usePut({
        url: 'get_opening_balance',
        onSuccess: (res) => {
            onSuccess?.(res)
        },
        onError: (err) => {
            //   const {status,data} = err.response
            //   if(status >= 400 || status <= 499){
            //     toast.error(data?.message)
            //   }else if(status >= 500 || status <= 599){
            //     toast.error('Internal server error')
            //   }
        },
        refreshUrl: 'petty_cash_listing'
    })
    const handleSubmitForm = (data) => {
        let fd = {
            voucher_date: data.voucher_date
        }
        // console.log(fd);
        mutateOpeningBalance.mutate(data)
    }
    return { mutateOpeningBalance,handleSubmitForm }
}

export default useGetOpeningBalance